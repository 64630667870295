export default `

mutation updateProbe(
    $id:ID!, 
    $recordId: String!, 
    $base: ProbeInput, 
    $data: HTTPProbeInput
){
    updateProbe(
        id: $id, 
        recordId: $recordId, 
        base: $base,
        data: $data
    ){
        id
        recordId
        name
        type
        enabled
        frequency
        autoSwitchDns
        sensitivity
        timeout
    }
}
`