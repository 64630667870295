export default `

mutation addFailoverRecord(
    $id: String,
    $domainId: String,
    $type: String,
    $name: String
    $ttl: Int, 
    $primaryValues: String
    $secondaryValues: String
){
    addFailoverRecord(
        id: $id,
        domainId: $domainId,
        name: $name,
        type:  $type,
        ttl: $ttl, 
        primaryValues: $primaryValues
        secondaryValues: $secondaryValues
    ){
        id
        domainId
        type
        policy
        ttl
        name
    }
}

`