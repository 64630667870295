import React from 'react';
import {
  Button,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  useTranslate,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  useDataProvider,
  EditButton,
  ShowButton,
  Pagination
} from 'react-admin';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { linkToRecord } from 'ra-core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { cloneElement, useMemo } from 'react';
import PlusEvent from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

import * as DomainStore from '../store/domain';
import { AddRecordEmptyPage } from './AddRecordEmpty';
import { preCommitCallback } from '../components/bulk';
import { SwitchDNSField } from '../components/SwitchDNSField'

import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';
import { truncate } from '../components/truncate'

const useStyles = makeStyles({
  current: {
    color: "#fc4b6c"
  }
})

const IdField = ({ source, basePath = '', record = {} }: any) => <Link to={`${linkToRecord(basePath, record && record.id)}/show`}><span>{record[source]}</span></Link>;

const CountField = (props: any) => {
  const { total } = props;
  return (<span>{total}</span>)
};

const RecordValueField = ({ source, record = {} }: any) => {
  const classes = useStyles();
  
  if (record.policy == "SIMPLE"){
    var value = truncate(record.data.values[0], 40, false).string

  }

  return (
    <>
      {record.policy == "SIMPLE" &&
        <span>{record.data && value}</span>
      }
      {record.policy == "FAILOVER" &&
        <SwitchDNSField record={record} />
      }
    </>
  );
}

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    onCreate,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);

  // console.log('ActionList props: ', props);

  const config = {
    logging: false,
    // A function to translate the CSV rows on import 
    preCommitCallback: (action: "create" | "overwrite", values: any[]) => preCommitCallback(action, values, currentDomain)
  }
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {/* <CreateButton basePath={basePath} /> */}
      {/* Add your custom actions */}
      <Button
        onClick={() => {
          dispatch(push('/records/create'))
        }}
        label={translate('record.add.title')}
      >
        <PlusEvent />
      </Button>
      <ImportButton {...props} {...config} />
      <ExportButton domain={props.domain} sort={props.currentSort} />
      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        // filterValues={filterValues}
        filterValues={{domain: "Z07853852CF31KYWXJ4VV"}}
        maxResults={maxResults}
      /> */}
    </TopToolbar>
  );
}

const ExportButton = (props: any) => {

  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const onClick = async (event: any) => {
    // console.log('try to export with ', props.domain)
    const result = await dataProvider.getList("records", {
      filter: {domain: props.domain}, 
      pagination: {perPage: 1000, page: 1},
      sort: props.sort
    })

    // console.log('with result: ', result)
    const recordsForExport = result.data.map((record: any) => {
      const { name, type, ttl, data } = record; 
      // console.log('with value: ', data)
      const value = data.values ? data.values[0] : data.primaryValues
      return {name, type, ttl, value};
  });
  jsonExport(recordsForExport, {
      headers: ['type', 'name', 'value', 'ttl'] // order fields in the export
  }, (err: any, csv: any) => {
      downloadCSV(csv, props.domain); // download as 'posts.csv` file
  });    

  }

  return (
    <Button
    onClick={onClick}
    label={translate('record.export.button')}
  ></Button>  
  )
}

const PostFilter = (props: any) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const exporter = (posts: any) => {

  console.log('in exporter with: ', posts);
  const postsForExport = posts.map((post: any) => {
      const { backlinks, author, ...postForExport } = post; // omit backlinks and author
      postForExport.author_name = post.author.name; // add a field
      return postForExport;
  });
  jsonExport(postsForExport, {
      headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
  }, (err: any, csv: any) => {
      downloadCSV(csv, 'posts'); // download as 'posts.csv` file
  });
};

export const RecordList = (props: any) => {

  console.log('received props: ', props)
  const translate = useTranslate();

  const { record, basePath = '' } = props;
  return (
    <>
      {/* <Box mt={2}><Typography align="center" variant="h4">{translate("record.list.title")}</Typography></Box> */}
      <List
        {...props}
        filter={{ domain: props.options.domain }}
        filters={<PostFilter />}
        title={translate("record.list.title")}
        actions={<ListActions domain={props.options.domain}/>}
        pagination={<PostPagination />}
        sort={{ field: 'name', order: 'ASC' }}
        empty={<AddRecordEmptyPage />}
        // exporter={exporter}
      >
        <Datagrid>
          <TextField source="name" />
          <ReferenceField label="Domain" source="domainId" reference="domains">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="type" />
          <TextField source="ttl" />
          <TextField source="policy" />
          <RecordValueField source="values" />
          {/* <IdField source="id" /> */}
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  )
};