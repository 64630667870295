export default `

mutation addDomain(
    $name: String,
    $type: String,
){
    addDomain(
        name: $name,
        type:  $type,
    ){
        id
    }
}

`