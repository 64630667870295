import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledHint, StyledForm, StyledInput, StyledCardActions } from '../components/auth'
import LockIcon from '@material-ui/icons/Lock';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import authProvider from '../authProvider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AuthSelectors from '../store/selectors/auth'
import * as AuthActions from '../store/actions/auth'
const _ = require('lodash');


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

interface FormValues {
  password: string;
  confirmPassword: string;
}

const { Form } = withTypes<FormValues>();

export const NewPasswordComponent = ({ currentUser, onResult }: any) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const history = useHistory();
  const notify = useNotify();
  const location = useLocation();

  const user = currentUser;
  // const user = useSelector(AuthSelectors.getUserData)

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    console.log('on handle submit')
    // const _user = _.cloneDeep( user );
    const result = await authProvider.challengeNewPassword(user, values.password);
    console.log('with result: ', result);
    onResult(result);
    setLoading(false);
    // history.push({ pathname: '/login', state: {} });
    // dispatch(AuthActions.willConfirmChallengePasswordReset());
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
              <StyledAvatar>
                <IconAvatar>
                  <LockIcon />
                </IconAvatar>
              </StyledAvatar>
              <StyledHint>Hint: insert new password</StyledHint>
              <StyledForm>
                <StyledInput>
                  <Field
                    autoFocus
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('domain.auth.new')}
                    disabled={loading}
                    type={"password"}
                  />
                </StyledInput>
                <StyledInput>
                  <Field
                    autoFocus
                    name="confirmpassword"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('domain.auth.confirm')}
                    disabled={loading}
                    type={"password"}
                  />
                </StyledInput>
              </StyledForm>
              <StyledCardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      thickness={2}
                      style={{ marginRight: 10 }}
                    />
                  )} {translate('domain.auth.newpassword.button')}
                </Button>
              </StyledCardActions>
        </form>
      )}
    />
  )
}
