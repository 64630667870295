export default `

query getFailoverRecords {
  getFailoverRecords {
    domainId
    id
    name
    policy
    ttl
    type
    currentValue
    data {
      ... on FailOverCacheRecordData {
        primaryValues
        secondaryValues
      }
    }
  }
  getAllProbes {
    domainId
    enabled
    frequency
    id
    name
    recordId
    sensitivity
    timeout
    type
    status
    statusDescription
    statusUpdateDate
    data {
      ... on HttpProbe {
        url
        followRedirects
      }
      ... on DnsProbe {
        server
        port
        dnsSec
        protocol
        query
        rdBit
        response
        value
      }
    }
  }

  listDomains{
    id
    name
  }

  getAllRecords {
    id
    name
  }
}
`