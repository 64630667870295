export default `

query getNotification ($id: String) {
    getNotification (id:$id){
        domainId
        recordId
        id
        data {
            type
            address
            enabled
        }
    }  
}
  
`

