export enum AuthTypes {
  WILL_CHALLENGE_NEW_PASSWORD = 'auth/WILL_CHALLENGE_NEW_PASSWORD',
  WILL_SIGNUP_NEW_USER = 'auth/WILL_SIGNUP_NEW_USER',
  DID_SIGNUP_NEW_USER = 'auth/DID_SIGNUP_NEW_USER',
  WILL_CONFIRM_SIGNUP_NEW_USER = 'auth/WILL_CONFIRM_SIGNUP_NEW_USER',
  DID_CONFIRM_SIGNUP_NEW_USER = 'auth/DID_CONFIRM_SIGNUP_NEW_USER',
  WILL_CHALLENGE_MFA_SETUP = "auth/WILL_CHALLENGE_MFA_SETUP",
  DID_CHALLENGE_MFA_SETUP = "auth/DID_CHALLENGE_MFA_SETUP",
  WILL_CONFIRM_MFA_SETUP = "auth/WILL_CONFIRM_MFA_SETUP",
  DID_CONFIRM_MFA_SETUP = "auth/DID_CONFIRM_MFA_SETUP",
  DID_CONFIRM_MFA_SETUP_FAIL = "auth/DID_CONFIRM_MFA_SETUP_FAIL",
  WILL_CHALLENGE_MFA = "auth/WILL_CHALLENGE_MFA",
  WILL_CONFIRM_SIGNIN = "auth/WILL_CONFIRM_SIGNIN",
  DID_CONFIRM_SIGNIN = "auth/DID_CONFIRM_SIGNIN",
  DID_CONFIRM_SIGNIN_FAIL = "auth/DID_CONFIRM_SIGNIN_FAIL",
  WILL_REQUEST_PASSWORD_RESET = "auth/WILL_REQUEST_PASSWORD_RESET",
  DID_REQUEST_PASSWORD_RESET = "auth/DID_REQUEST_PASSWORD_RESET",
  DID_REQUEST_PASSWORD_RESET_FAIL = "auth/DID_REQUEST_PASSWORD_RESET_FAIL",
  WILL_CONFIRM_PASSWORD_RESET = "auth/WILL_CONFIRM_PASSWORD_RESET",
  DID_CONFIRM_PASSWORD_RESET = "auth/DID_CONFIRM_PASSWORD_RESET",
  DID_CONFIRM_PASSWORD_RESET_FAIL = "auth/DID_CONFIRM_PASSWORD_RESET_FAIL",
  WILL_CHALLENGE_PASSWORD_RESET = "auth/WILL_CHALLENGE_PASSWORD_RESET",
  WILL_CONFIRM_CHALLENGE_PASSWORD_RESET = "auth/WILL_CONFIRM_CHALLENGE_PASSWORD_RESET"
}

export enum AuthState {
  AUTH_NONE,
  AUTH_SIGNUP,
  AUTH_SIGNUP_CONFIRM,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_CONFIRM
}

export interface AuthInitialState {
  user: any,
  authState: AuthState,
  otpCode: string,
  error: null
}

export interface WillChallengeNewPassword {
  readonly type: AuthTypes.WILL_CHALLENGE_NEW_PASSWORD;
  readonly user: any
}

export interface WillSignupNewUser {
  readonly type: AuthTypes.WILL_SIGNUP_NEW_USER;
  readonly username: string;
  readonly email: string;
  readonly password: string;
}

export interface DidSignupNewUser {
  readonly type: AuthTypes.DID_SIGNUP_NEW_USER;
  readonly username: string;
}

export interface WillConfirmSignupNewUser {
  readonly type: AuthTypes.WILL_CONFIRM_SIGNUP_NEW_USER;
  readonly username: string;
  readonly code: string;
}

export interface DidConfirmSignupNewUser {
  readonly type: AuthTypes.DID_CONFIRM_SIGNUP_NEW_USER;
}

export interface WillChallengeMFASetup {
  readonly type: AuthTypes.WILL_CHALLENGE_MFA_SETUP;
  readonly user: any;
}

export interface DidChallengeMFASetup {
  readonly type: AuthTypes.DID_CHALLENGE_MFA_SETUP;
  readonly code: string;
}

export interface WillConfirmMFASetup {
  readonly type: AuthTypes.WILL_CONFIRM_MFA_SETUP;
  readonly user: any;
  readonly code: any;
  readonly mfaType: any;
}

export interface DidConfirmMFASetup {
  readonly type: AuthTypes.DID_CONFIRM_MFA_SETUP;
}

export interface DidConfirmMFASetupFail {
  readonly type: AuthTypes.DID_CONFIRM_MFA_SETUP_FAIL;
  error: any;
}

export interface WillChallengeMFA {
  readonly type: AuthTypes.WILL_CHALLENGE_MFA;
  user: any;
}

export interface WillConfirmSignin {
  readonly type: AuthTypes.WILL_CONFIRM_SIGNIN;
  readonly user: any;
  readonly code: any;
  readonly mfaType: any;
}

export interface DidConfirmSignin {
  readonly type: AuthTypes.DID_CONFIRM_SIGNIN;
  user: any;
}

export interface DidConfirmSigninFail {
  readonly type: AuthTypes.DID_CONFIRM_SIGNIN_FAIL;
  error: any;
}

export interface WillRequestPasswordReset {
  readonly type: AuthTypes.WILL_REQUEST_PASSWORD_RESET;
  readonly username: any;
}

export interface DidRequestPasswordReset {
  readonly type: AuthTypes.DID_REQUEST_PASSWORD_RESET;
  data: any;
}

export interface DidRequestPasswordResetFail {
  readonly type: AuthTypes.DID_REQUEST_PASSWORD_RESET_FAIL;
  error: any;
}

export interface WillConfirmPasswordReset {
  readonly type: AuthTypes.WILL_CONFIRM_PASSWORD_RESET;
  readonly username: any;
  readonly code: any;
  readonly password: any;
}

export interface DidConfirmPasswordReset {
  readonly type: AuthTypes.DID_CONFIRM_PASSWORD_RESET;
  data: any;
}

export interface DidConfirmPasswordResetFail {
  readonly type: AuthTypes.DID_CONFIRM_PASSWORD_RESET_FAIL;
  error: any;
}

export interface WillChallengePasswordReset {
  readonly type: AuthTypes.WILL_CHALLENGE_PASSWORD_RESET;
}

export interface WillConfirmChallengePasswordReset {
  readonly type: AuthTypes.WILL_CONFIRM_CHALLENGE_PASSWORD_RESET;
}


export type ActionTypes =
  | WillChallengeNewPassword
  | WillSignupNewUser
  | DidSignupNewUser
  | WillConfirmSignupNewUser
  | DidConfirmSignupNewUser
  | WillChallengeMFASetup
  | DidChallengeMFASetup
  | WillConfirmMFASetup
  | DidConfirmMFASetup
  | DidConfirmMFASetupFail
  | WillChallengeMFA
  | WillConfirmSignin
  | DidConfirmSignin
  | DidConfirmSigninFail
  | WillRequestPasswordReset
  | DidRequestPasswordReset
  | DidRequestPasswordResetFail
  | WillConfirmPasswordReset
  | DidConfirmPasswordReset
  | DidConfirmPasswordResetFail
  | WillChallengePasswordReset
  | WillConfirmChallengePasswordReset
  ;