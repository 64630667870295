import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router'

import * as AuthTypes from '../types/auth'
import * as AuthActions from '../actions/auth'
import authProvider from '../../authProvider'

export function* doLoadSagas() {

  console.log('LOADED AUTH SAGA')
  yield all(
    [
      yield takeLatest(AuthTypes.AuthTypes.WILL_CHALLENGE_NEW_PASSWORD, willChallengeNewPassword),
      yield takeLatest(AuthTypes.AuthTypes.WILL_SIGNUP_NEW_USER, willSignupNewPassword),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CONFIRM_SIGNUP_NEW_USER, willConfirmSignupNewPassword),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CHALLENGE_MFA_SETUP, willChallengeMFASetup),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CONFIRM_MFA_SETUP, willConfirmMFASetup),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CHALLENGE_MFA, willChallengeMFA),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CONFIRM_SIGNIN, willConfirmSignin),
      yield takeLatest(AuthTypes.AuthTypes.WILL_REQUEST_PASSWORD_RESET, willRequestPasswordReset),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CONFIRM_PASSWORD_RESET, willConfirmPasswordReset),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CHALLENGE_PASSWORD_RESET, willChallengePasswordReset),
      yield takeLatest(AuthTypes.AuthTypes.WILL_CONFIRM_CHALLENGE_PASSWORD_RESET, willConfirmChallengePasswordReset)
    ]
  );
}

function* willChallengeNewPassword() {
  console.log('in willChallengeNewPassword saga')
  yield put(push('/auth/newpassword'))
}

function* willConfirmChallengePasswordReset() {
  console.log('in willConfirmChallengePasswordReset saga')

  window.open("http://localhost:8080/#/login", '_top');
}

function* willSignupNewPassword(action: AuthTypes.WillSignupNewUser) {
  console.log('in willSignupNewPassword saga');
  yield call(authProvider.signupUser, action.username, action.email, action.password);
  yield put(AuthActions.didSignupNewUser(action.username));
}

function* willConfirmSignupNewPassword(action: AuthTypes.WillConfirmSignupNewUser) {
  console.log('in willConfirmSignupNewPassword saga')
  yield call(authProvider.confirmSignupUser, action.username, action.code);
  yield put(AuthActions.didConfirmSignupNewUser(action.username));
  yield put(push('/login'))
}

function* willChallengeMFASetup(action: AuthTypes.WillChallengeMFASetup) {
  console.log('in willChallengeMFASetup saga')
  const code = yield call(authProvider.setupTOTP, action.user);
  yield put(AuthActions.didChallengeMFASetup(code))
  yield put(push('/auth/mfa/setup'))
}

function* willConfirmMFASetup(action: AuthTypes.WillConfirmMFASetup) {
  try {
    const result = yield call(authProvider.confirmTOTP, action.user, action.code, action.mfaType)
    yield put(AuthActions.didConfirmMFASetup())
    yield put(push('/login'))
  } catch (error) {
    yield put(AuthActions.didConfirmMFASetupFail(error))
  }

}

function* willChallengeMFA(action: AuthTypes.WillChallengeMFA) {
  // try {
  // const result = yield call(authProvider.confirmSignin, action.user, action.code, action.mfaType)
  // yield put(AuthActions.didConfirmMFASetup())
  console.log('in willChallengeMFA saga')
  yield put(push('/auth/mfa/confirm'))
  // } catch (error) {
  // yield put(AuthActions.didConfirmMFASetupFail(error))
  // }
}

function* willConfirmSignin(action: AuthTypes.WillConfirmSignin) {
  console.log('in willConfirmSignin saga')
  try {
    const loggedUser = yield call(authProvider.confirmSignin, action.user, action.code, action.mfaType)
    console.log('loggedUser: ', loggedUser)
    yield put(AuthActions.didConfirmSignin(loggedUser))
    localStorage.setItem('username', action.user.username);
    yield put(push('/'))
  } catch (error) {
    yield put(AuthActions.didConfirmSigninFail(error))
  }
}

function* willRequestPasswordReset(action: AuthTypes.WillRequestPasswordReset) {
  console.log('willRequestPasswordReset')
  try {
    const data = yield call(authProvider.forgotPassword, action.username)
    console.log('data: ', data)
    yield put(AuthActions.didRequestPasswordReset(data))
  } catch (error) {
    yield put(AuthActions.didRequestPasswordResetFail(error))
  }
}

function* willConfirmPasswordReset(action: AuthTypes.WillConfirmPasswordReset) {
  console.log('willConfirmPasswordReset')
  try {
    const data = yield call(authProvider.forgotPasswordConfirm, action.username, action.code, action.password)
    console.log('data: ', data)
    yield put(AuthActions.didConfirmPasswordReset(data))
    yield put(push('/login'))
  } catch (error) {
    yield put(AuthActions.didConfirmPasswordResetFail(error))
  }
}

function* willChallengePasswordReset(action: AuthTypes.WillChallengePasswordReset) {
  yield put(push('/auth/forgot'))
}