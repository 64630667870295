// in src/createAdminStore.js
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { uiReducer } from './reducers/ui'
import { authReducer } from './reducers/auth'
import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit"

import { reducer as domainReducer } from './domain'

import * as AuthSagas from './sagas/auth'

import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
} from 'react-admin';

export default ({
    authProvider,
    dataProvider,
    history,
}: any) => {
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        /* add your own reducers here */
        ui: uiReducer,
        auth: authReducer,
        domain: domainReducer
    });
    const resettableAppReducer = (state: any, action: any) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    const saga = function* rootSaga() {
        yield all(
            [
                fork(adminSaga(dataProvider, authProvider)),
                // add your own sagas here
                AuthSagas.doLoadSagas()
            ]
        );
    };
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers =
        (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined' &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;
  
    const store = createStore(
        resettableAppReducer,
        { /* set your initial state here */ },
        composeEnhancers(
            applyMiddleware(
                ...getDefaultMiddleware(),
                sagaMiddleware,
                routerMiddleware(history),
                // add your own middlewares here
            ),
            // add your own enhancers here
        ),        
    );
    sagaMiddleware.run(saga);
    return store;
};