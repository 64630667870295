import React, { useState } from 'react';
import {
  TextInput,
  ReferenceField,
  TextField,
  SimpleForm,
  Edit,
  SelectInput,
  NumberInput,
  required,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';


export const EditProbe = (props: any) => {
  const [type, setType] = useState("HTTP");
  console.log('in Probe Edit with: ', props)
  return (
    <Edit {...props} title="Edit Probe">
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Domain" source="domainId" reference="domains">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Record" source="recordId" reference="records">
          <TextField source="name" />
        </ReferenceField>
        <TextInput label="Probe Name" source="name" fullWidth={true} />
        <div style={{ flexGrow: 1, width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <SelectInput defaultValue="" label="Probe Type" fullWidth={true} source="type" choices={[
                { id: 'HTTP', name: 'HTTP' },
                { id: 'DNS', name: 'DNS' },
                { id: 'FTP', name: 'FTP' },
                { id: 'Imap4', name: 'Imap4' },
                { id: 'NTP', name: 'NTP' },
                { id: 'Ping', name: 'Ping' },
                { id: 'Pop3', name: 'Pop3' },
                { id: 'SMTP', name: 'SMTP' },
                { id: 'whois', name: 'Whois' }
              ]}
                value={type}
                onChange={
                  (e: any) => {
                    setType(e.target.value)
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <BooleanInput label="Enable check" fullWidth={true} source="enabled" />
            </Grid>
          </Grid>
          <Grid item xs={10} sm={6} md={4} xl={2} lg={3} >
            <SelectInput defaultValue="" label="Auto Switch DNS" fullWidth={true} source="autoSwitchDns" choices={[
              { id: 'immediately', name: 'Immediately' },
              { id: '1 minute switch', name: 'Switch in 1 minute', disabled:true },
              { id: '5 minutes switch', name: 'Switch in 5 minutes', disabled:true },
              { id: '15 minutes switch', name: 'Switch in 15 minutes', disabled:true },
              { id: '30 minutes switch', name: 'Switch in 30 minutes', disabled:true },
              { id: 'never', name: 'Never' }
            ]} />
          </Grid>
        </div>
        <SelectInput defaultValue="" label="Check Frequency" fullWidth={true} source="frequency" choices={[
          { id: '1', name: '1 minute' },
          { id: '3', name: '3 minutes' },
          { id: '5', name: '5 minutes' },
          { id: '15', name: '15 minutes' },
          { id: '30', name: '30 minutes' },
          { id: '60', name: '1 hour' },
          { id: '240', name: '4 hours' },
          { id: '360', name: '6 hours' },
          { id: '720', name: '12 hours' },
          { id: '1440', name: '1 day' }
        ]} />
        <SelectInput defaultValue="" label="Sensitivity" fullWidth={true} source="sensitivity" choices={[
          { id: 'veryhigh', name: 'Very High (no recheck)' },
          { id: 'high', name: 'High (2 rechecks)' },
          { id: 'medium', name: 'Medium (5 rechecks)' },
          { id: 'low', name: 'Low (7 rechecks)' },
          { id: 'verylow', name: 'Very Low (10 rechecks)' }
        ]} />
        <NumberInput label="Timeout" defaultValue={5} source="timeout" />
        {(type === "HTTP") &&
          <div style={{ flexGrow: 1, width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10}>
                <TextInput label="Url" source="data.url" fullWidth={true} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <BooleanInput label="Follow redirects" fullWidth={true} source="data.followRedirects" />
              </Grid>
            </Grid>
          </div>
        }
        {(type === "DNS") &&
          <>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <TextInput label="DNS server" fullWidth={true} source="dnsserver" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <NumberInput label="DNS port" fullWidth={true} defaultValue={53} source="dnsport" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <SelectInput defaultValue="" label="Protocol" fullWidth={true} source="dnsprotocol" choices={[
                    { id: 'udp', name: 'udp' },
                    { id: 'tcp', name: 'tcp' }
                  ]} />
                </Grid>
              </Grid>
            </div>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <SelectInput defaultValue="" label="Query" fullWidth={true} source="dnsquery" choices={[
                    { id: 'a', name: 'A' },
                    { id: 'aaaa', name: 'AAAA' },
                    { id: 'any', name: 'ANY' },
                    { id: 'cname', name: 'CNAME' },
                    { id: 'mx', name: 'MX' },
                    { id: 'ns', name: 'NS' },
                    { id: 'ptr', name: 'PTR' },
                    { id: 'soa', name: 'SOA' },
                    { id: 'srv', name: 'SRV' },
                    { id: 'txt', name: 'TXT' },
                  ]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput defaultValue="" label="RD bit" fullWidth={true} source="dnsrdbit" choices={[
                    { id: '1', name: '1' },
                    { id: '0', name: '0' }
                  ]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput defaultValue="" label="DNSSEC" fullWidth={true} source="dnssec" choices={[
                    { id: 'notverify', name: 'Do not verify DNSSEC' },
                    { id: 'verify', name: 'Verify DNSSEC authentication data' }
                  ]} />
                </Grid>
              </Grid>
            </div>
            <TextInput label="Value" source="dnsvalue" fullWidth={true} />
            <TextInput label="Expected Response" source="dnsresponse" fullWidth={true} />
          </>
        }
      </SimpleForm>
    </Edit>
  );
}