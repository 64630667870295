import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const domainSlice = createSlice({
  name: 'domain',
  initialState: {
    currentDomain: {}
  },
  reducers: {
    didSelectDomain(state, action: PayloadAction<any>) { state.currentDomain = action.payload.name ? action.payload : action.payload.data },
  }
})

export const { actions, reducer } = domainSlice
export const { didSelectDomain } = actions

export const selectors = {
  getCurrentDomain: (state: any) => state.domain.currentDomain
}
export const { getCurrentDomain } = selectors