import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import {
    Create, SimpleForm, Button, TextInput, SelectInput, FormWithRedirect, SaveButton, useDataProvider, required,
    ReferenceInput, BooleanInput, NumberInput
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import * as DomainStore from '../store/domain'
import { Grid, Box, DialogActions } from '@material-ui/core';
import { parse } from 'query-string';
import { InputTextField } from '../components/InputTextField';
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LiveHelp from '@material-ui/icons/LiveHelp'
import { useHistory } from "react-router-dom";


const styles = {
    button: {
        marginTop: '1em'
    }
};
interface FormValues {
    recordName: string;
    recordtype: string;
    primaryIp: string;
    secondaryIp: string;
}
const RecordPolicies = ["SIMPLE", "FAILOVER"];
const SimpleRecordPolicies = ["SIMPLE"];

const RecordTypes = [
    "SOA",
    "A",
    "TXT",
    "NS",
    "CNAME",
    "MX",
    "NAPTR",
    "PTR",
    "SRV",
    "SPF",
    "AAAA",
    "CAA"
];

const RecordTypesItems = RecordTypes.map((r, i) => ({ id: r, name: r }))
const RecordPoliciesItems = RecordPolicies.map((r, i) => ({ id: r, name: r }))
const SimpleRecordPoliciesItems = SimpleRecordPolicies.map((r, i) => ({ id: r, name: r }));
const string_validation = (s: any) => (typeof s !== 'string') ? "Must be a string!" : undefined;

const FullNameField = ({ record, label }: any) => <><span>{record.name}</span></>;
FullNameField.defaultProps = { label: 'Name', addLabel: true };

const { Form } = withTypes<FormValues>();

const ip_validation = (value: any) => (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value) ? undefined : "Invalid IP")
const number_validation = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)
const geq_number_validation = (value: any) => (isNaN(value) ? 'Must be equal or greater than 0' : ((value < 0) ? "Must be > 0" : undefined))
const number = (message = 'Must be a number') =>
    (value: any) => value && isNaN(Number(value)) ? message : undefined;

export const AddRecordPage = (props: any) => {


    const steps = [
        {
            selector: '.first-step',
            content: <p>Inserisci il <span className="text-success"><strong>nome</strong></span> del record</p>
        },
        {
            selector: '.second-step',
            content: <p>Seleziona la policy da utilizzare <span className="text-success"><strong>(il failover si può utilizzare solo con record di tipo A)</strong></span></p>,
        },
        {
            selector: '.third-step',
            content: <p>Seleziona il <strong>tipo</strong> di record da utilizzare</p>,
        },
        {
            selector: '.fourth-step',
            content: <p>Seleziona il <strong>TTL</strong></p>,
        },
        {
            selector: '.fifth-step',
            content: <p>Inserisci il <strong>valore</strong> da utilizzare sul record</p>,
        }
    ];

    const translate = useTranslate();
    const { domain_id: domain_id } = parse(props.location.search);
    const [policy, setPolicy] = useState("SIMPLE");
    const [rtype, setRType] = useState("A");
    const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
    const [saving, setSaving] = useState(false);
    console.log('in currentDomain', currentDomain);
    console.log('in AddRecordPage', props, domain_id);
    const disableBody = (target: any) => disableBodyScroll(target)
    const enableBody = (target: any) => enableBodyScroll(target)
    const [isTourOpen, setIsTourOpen] = useState(false);
    const dataProvider = useDataProvider();
    const history = useHistory();

    return (
        <>

            <Create redirect="/records" title={translate('record.add.title')} {...props}>
                <div style={{ padding: 20, margin: 10 }}>
                    <FormWithRedirect
                        resource="posts"
                        initialValues={{ ttl: 120, domainId: currentDomain.id, domainName: currentDomain.name, policy: "SIMPLE", type: "A" }}
                        save={async (data: any) => {

                            setSaving(true);
                            const result = await dataProvider.create('records', { data: data });
                            console.log('with create result: ', result)
                            setSaving(false);
                            history.push("/records")
                        }}
                        {...props}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            loading
                        }: any) => (
                                <>
                                    <div style={{ flexGrow: 1, width: '100%' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <InputTextField label="Nome Dominio" record={currentDomain} source="name" />
                                            </Grid>
                                        </Grid>

                                        <TextInput className="first-step" options={{ fullWidth: true }} label={translate("record.label.recordName")} source="name" />

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>
                                                <SelectInput className="second-step" options={{ fullWidth: true }} label={translate("record.label.policy")} validate={[required("Record type required")]} source="policy" defaultValue={policy}
                                                    value={policy}
                                                    onChange={
                                                        (e: any) => {
                                                            setPolicy(e.target.value)
                                                        }}
                                                    // choices={RecordPoliciesItems} />
                                                    choices={(() => {
                                                        return (rtype === "A") ? RecordPoliciesItems : SimpleRecordPoliciesItems;
                                                    })()} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>

                                                <SelectInput className="third-step" options={{ fullWidth: true }} label={translate("record.label.type")} validate={[required("Policy required")]} source="type" defaultValue={rtype}
                                                    value={rtype}
                                                    onChange={
                                                        (e: any) => {
                                                            setRType(e.target.value)
                                                        }}
                                                    choices={RecordTypesItems} />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>

                                                <TextInput className="fourth-step" options={{ fullWidth: true }} label={translate('record.label.ttl')} source="ttl" validate={geq_number_validation} />
                                            </Grid>

                                        </Grid>


                                        {/* {(policy === "SIMPLE") && <TextInput options={{ fullWidth: true }} label={translate('record.label.value')} source="values" validate={ip_validation} />} */}
                                        {(policy === "SIMPLE" && rtype === "A") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.ips')} source="values" validate={ip_validation} />}
                                        {(policy === "SIMPLE" && rtype === "AAAA") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.AAAA')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "CAA") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.CAA')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "CNAME") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.CNAME')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "MX") && <TextInput className="fifth-step" options={{ fullWidth: true }} multiline label={translate('domain.domain.newrecord.simpleValues.MX')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "NAPTR") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.NAPTR')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "NS") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.NS')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "PTR") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.PTR')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "SPF") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.SPF')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "SRV") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.SRV')} source="values" validate={string_validation} />}
                                        {(policy === "SIMPLE" && rtype === "TXT") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.TXT')} source="values" validate={string_validation} />}
                                        {(policy === "FAILOVER") && (rtype === "A") &&
                                            < div >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="primaryValue" validate={ip_validation} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>

                                                        <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.secondaryIp')} source="secondaryValue" validate={ip_validation} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }
                                        {/* {(policy === "FAILOVER") && (rtype === "CNAME") &&
                                            < div >
                                                <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="primaryValues" validate={ip_validation} />
                                                <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.hcEndpoint')} source="healthCheckEndPoint" validate={ip_validation} />
                                            </div>
                                        } */}
                                    </div>
                                    <DialogActions>
                                        <SaveButton
                                            handleSubmitWithRedirect={
                                                handleSubmitWithRedirect
                                            }
                                            pristine={pristine}
                                            saving={saving}
                                            disabled={loading}
                                            label="crea il record"
                                        />
                                        <Button
                                            // variant="outlined"
                                            size="medium"
                                            color="primary"
                                            // label=" "
                                            component="span"
                                            onClick={() => {
                                                setIsTourOpen(true);
                                            }}
                                        >
                                            <LiveHelp />
                                        </Button>
                                    </DialogActions>
                                    <Tour
                                        steps={steps}
                                        isOpen={isTourOpen}
                                        onRequestClose={() => setIsTourOpen(false)}
                                        onAfterOpen={disableBody}
                                        onBeforeClose={enableBody}
                                        accentColor="#fc4b6c"
                                        maskSpace={32}
                                        rounded={32}
                                    />
                                </>
                            )
                        }
                    />
                </div>
            </Create >
        </>
    )


    // const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
    // console.log('currentDomain: ', currentDomain);
    // const [type, setType] = useState("http");

    // return (
    //   <Create redirect="/probes" title="Add new probe" {...props}>
    //     <SimpleForm defaultValue={{ recordId: "", frequency: "1m", sensitivity: "medium", enabled: true }} redirect="list" >

    //       <div style={{ flexGrow: 1, width: '100%' }}>
    //         <Grid container spacing={3}>
    //           <Grid item xs={12} sm={6}>
    //             <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain name" source="domainName" validate={[required("Domain name required")]} defaultValue={currentDomain.name} />
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain Id" source="domainId" validate={[required("Domain Id required")]} defaultValue={currentDomain.id} />
    //           </Grid>
    //         </Grid>
    //       </div>

    //       <ReferenceInput
    //         source="recordId"
    //         label="Record Name"
    //         filter={{ isActive: true, domain: props.options.domain }}
    //         reference="records"
    //         fullWidth={true}
    //       // allowNull
    //       // format={(v: any) => (!v ? null : v)}
    //       >
    //         <SelectInput defaultValue="" optionValue="id" label="Record name" optionText="name" validate={[required("Record name required")]} source="records" />
    //       </ReferenceInput>
    //       <TextInput label="Probe Names" source="name" fullWidth={true} validate={[required("Probe names required")]} />
    //       <div style={{ flexGrow: 1, width: '100%' }}>
    //         <Grid container spacing={3}>
    //           <Grid item xs={12} sm={10}>
    //             <SelectInput defaultValue="" label="Probe Type" fullWidth={true} source="type" validate={[required("Prob type required")]} choices={[
    //               { id: 'http', name: 'HTTP' },
    //               { id: 'dns', name: 'DNS' },
    //               { id: 'ftp', name: 'FTP' },
    //               { id: 'imap4', name: 'Imap4' },
    //               { id: 'ntp', name: 'NTP' },
    //               { id: 'ping', name: 'Ping' },
    //               { id: 'pop3', name: 'Pop3' },
    //               { id: 'smtp', name: 'SMTP' },
    //               { id: 'whois', name: 'Whois' }
    //             ]}
    //               value={type}
    //               onChange={
    //                 (e: any) => {
    //                   setType(e.target.value)
    //                 }}
    //             />
    //           </Grid>
    //           <Grid item xs={12} sm={2}>
    //             <BooleanInput label="Enable check" fullWidth={true} source="enabled" />
    //           </Grid>
    //         </Grid>
    //         <Grid item xs={10} sm={6} md={4} xl={2} lg={3} >
    //           <SelectInput defaultValue="" label="Auto Switch DNS" fullWidth={true} source="autoSwitchProbe" validate={[required("Auto switch required")]} choices={[
    //           { id: '1 minute switch', name: 'Switch in 1 minute' },
    //           { id: '5 minutes switch', name: 'Switch in 5 minutes' },
    //           { id: '15 minutes switch', name: 'Switch in 15 minutes' },
    //           { id: '30 minutes switch', name: 'Switch in 30 minutes' }
    //         ]} />
    //         </Grid>
    //       </div>
    //       <SelectInput defaultValue="" label="Check Frequency" fullWidth={true} source="frequency" validate={[required("Check frequency required")]} choices={[
    //         { id: '1', name: '1 minute' },
    //         { id: '3', name: '3 minutes' },
    //         { id: '5', name: '5 minutes' },
    //         { id: '15', name: '15 minutes' },
    //         { id: '30', name: '30 minutes' },
    //         { id: '60', name: '1 hour' },
    //         { id: '240', name: '4 hours' },
    //         { id: '360', name: '6 hours' },
    //         { id: '720', name: '12 hours' },
    //         { id: '1440', name: '1 day' }
    //       ]} />
    //       <SelectInput defaultValue="" label="Sensitivity" fullWidth={true} source="sensitivity" validate={[required("Sensivity required")]} choices={[
    //         { id: 'veryhigh', name: 'Very High (no recheck)' },
    //         { id: 'high', name: 'High (2 rechecks)' },
    //         { id: 'medium', name: 'Medium (5 rechecks)' },
    //         { id: 'low', name: 'Low (7 rechecks)' },
    //         { id: 'verylow', name: 'Very Low (10 rechecks)' }
    //       ]} />
    //       <NumberInput label="Timeout" defaultValue={5} source="timeout" validate={[required("Timeout required")]} />
    //       {(type === "http") &&
    //         <>
    //           <TextInput label="Url" source="url" fullWidth={true} validate={[required("Url required")]} />
    //           <SelectInput defaultValue="" label="Redirects" fullWidth={true} source="redirects" validate={[required("Redirects required")]} choices={[
    //             { id: 'follow', name: 'Follow redirect' },
    //             { id: 'notfollow', name: 'Do not follow redirect' }
    //           ]} />
    //         </>
    //       }
    //       {(type === "dns") &&
    //         <>
    //           <div style={{ flexGrow: 1, width: '100%' }}>
    //             <Grid container spacing={3}>
    //               <Grid item xs={12} sm={8}>

    //                 <TextInput label="DNS server" fullWidth={true} source="dnsserver" />
    //               </Grid>
    //               <Grid item xs={12} sm={2}>
    //                 <NumberInput label="DNS port" fullWidth={true} defaultValue={53} source="dnsport" />
    //               </Grid>
    //               <Grid item xs={12} sm={2}>
    //                 <SelectInput defaultValue="" label="Protocol" fullWidth={true} source="dnsprotocol" choices={[
    //                   { id: 'udp', name: 'udp' },
    //                   { id: 'tcp', name: 'tcp' }
    //                 ]} />
    //               </Grid>
    //             </Grid>
    //           </div>
    //           <div style={{ flexGrow: 1, width: '100%' }}>
    //             <Grid container spacing={3}>
    //               <Grid item xs={12} sm={4}>

    //                 <SelectInput defaultValue="" label="Query" fullWidth={true} source="dnsquery" choices={[
    //                   { id: 'a', name: 'A' },
    //                   { id: 'aaaa', name: 'AAAA' },
    //                   { id: 'any', name: 'ANY' },
    //                   { id: 'cname', name: 'CNAME' },
    //                   { id: 'mx', name: 'MX' },
    //                   { id: 'ns', name: 'NS' },
    //                   { id: 'ptr', name: 'PTR' },
    //                   { id: 'soa', name: 'SOA' },
    //                   { id: 'srv', name: 'SRV' },
    //                   { id: 'txt', name: 'TXT' },
    //                 ]} />
    //               </Grid>
    //               <Grid item xs={12} sm={4}>

    //                 <SelectInput defaultValue="" label="RD bit" fullWidth={true} source="dnsrdbit" choices={[
    //                   { id: '1', name: '1' },
    //                   { id: '0', name: '0' }
    //                 ]} />
    //               </Grid>
    //               <Grid item xs={12} sm={4}>

    //                 <SelectInput defaultValue="" label="DNSSEC" fullWidth={true} source="dnssec" choices={[
    //                   { id: 'notverify', name: 'Do not verify DNSSEC' },
    //                   { id: 'verify', name: 'Verify DNSSEC authentication data' }
    //                 ]} />
    //               </Grid>
    //             </Grid>
    //           </div>
    //           <TextInput label="Value" source="dnsvalue" fullWidth={true} />
    //           <TextInput label="Expected Response" source="dnsresponse" fullWidth={true} />
    //         </>
    //       }


    //     </SimpleForm>
    //   </Create >
    // )


};

export const AddRecordButton = withStyles(styles)((props: any) => {
    return (
        <Button
            className={props.classes.button}
            variant="outlined"
            component={Link}
            to={`/records/create`}
            label="Add a record"
            title="Add a record"
        >
            <AddIcon />
        </Button>
    )
});