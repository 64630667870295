export default `

query listNotifications(
    $domainId: String
){
  listNotifications(
    domainId: $domainId
    ){
      id
      domainId
      recordId
      data{
        type
        address
        enabled
      }
    }
}

`