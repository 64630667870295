import React, { useState } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  ShowButton,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  EditButton
} from 'react-admin';
import { List, Datagrid, TextField, BooleanField, ReferenceField, ReferenceManyField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {
  ReferenceInput,
  SimpleForm,
  Button,
  SelectInput,
} from 'react-admin';
import * as DomainStore from '../store/domain'
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);


export const StatusField = ({ source, record = {} }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {record.status == "down" &&
        <Chip label="down" color="primary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      }
      {record.status == "up" &&
        <Chip label="up" color="secondary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      }
      {record.status == "first" &&
        <Chip label="first" color="secondary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      }
      {record.status == undefined &&
        <Chip label="waiting" />
      }
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{record.statusDescription}</Typography>
      </Popover>
    </>
  );
}