// export default `

// query listRecords(
//     $domainName: String
//     $domainId: String
// ){
//   listRecords(
//     domainName: $domainName
//     domainId: $domainId
//     ){
//       id
//       name
//       type
//       ttl
//       healthcheckId
//       healthcheckIp
//       healthcheckPort
//       healthcheckType
//       failover
//       primaryIp
//       secondaryIp
//       domain
//       domainId
//       policy
//     }
// }

// `

export default `

query listRecords(
    $domainId: String,
    $perPage: Int,
    $page: Int
){
  listRecords(
    domainId: $domainId,
    perPage: $perPage,
    page: $page
    ){
      length
      items {
        id
        name
        type
        policy
        ttl
        domainId
        currentValue
        data{
          ... on SimpleCacheRecordData{
            values
        }
        ... on FailOverCacheRecordData{
            primaryValues
            secondaryValues
        }
      }
    }
  }
}
`