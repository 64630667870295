// in src/App.js
import React from 'react';
import {
  useTranslate
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  readonly: {
    lineHeight: '26px',
    fontSize: '1.1428571428571428rem'
  },
  label: {
    fontSize: '0.8rem',
    marginTop: 8,
    color: 'darkgrey'
  },
  textInput: {
    backgroundColor: "#fff",
    paddingLeft: 12,
    borderWidth: '1px',
    borderColor: '#eee',
    borderBottomColor: 'darkgrey',
    borderStyle: 'solid',
    fontFamily: 'Quicksand',
    marginBottom: 32,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  }
});

export const InputTextField = ({ record, label, source = "" }: any) => {

  const translate = useTranslate();
  const classes = useStyles();
  console.log('in inputTextField: ', record, label, source)
  return (
    <Box className={classes.textInput}>
      <Box className={classes.label}><span>{label}</span></Box>
      {source == "" ? (
        <Box className={classes.readonly}><span>{record.name}</span></Box>

      ) : (
          <Box className={classes.readonly}><span>{record[source]}</span></Box>

        )}
    </Box>
  )
}
