import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation, withRouter } from 'react-router-dom';
import { StyledCard, StyledTitle, StyledInputSecondary, MainDiv, IconAvatar, StyledIconForgot, StyledAvatar, StyledLink, StyledHint, StyledForm, StyledInput, StyledCardActions, StyledIconSignup } from '../components/auth'
import LockIcon from '@material-ui/icons/Lock';
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import authProvider from '../authProvider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AuthSelectors from '../store/selectors/auth'
import * as AuthActions from '../store/actions/auth'
import * as AuthTypes from '../store/types/auth'

const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined);


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

interface ForgotRequestFormValues {
  username: string;
}

interface ForgotResponseFormValues {
  username: string;
  password: string;
  confirmPassword: string;
  code: string;
}

const Form = withTypes<ForgotRequestFormValues | ForgotResponseFormValues>().Form;

const ForgotPasswordPage = () => {

  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const user = useSelector(AuthSelectors.getUserData)
  const dispatch = useDispatch()
  const authState: AuthTypes.AuthState = useSelector(AuthSelectors.getAuthState)

  const handleSubmit = async (values: any) => {
    setLoading(true);
    console.log('on handle submit')
    if (authState != AuthTypes.AuthState.AUTH_FORGOT_PASSWORD_CONFIRM) {
      dispatch(AuthActions.willRequestPasswordReset(values.username))
    } else {
      dispatch(AuthActions.willConfirmPasswordReset(values.username, values.code, values.password))
    }
  };

  //Reset Loading status when AUTH_SIGNUP_CONFIRM
  useEffect(() => {
    if (authState == AuthTypes.AuthState.AUTH_FORGOT_PASSWORD_CONFIRM) {
      setLoading(false);
    }
    return () => { }
  }, [authState])

  const required = (value: any) => (value ? undefined : 'Required')
  const email_validation = (value: any) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? undefined : "Invalid Email")
  const password_validation = (value: any) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i.test(value) ? undefined : "Password Must be at least  1 lowercase, 1 uppercase, 1 numeric, 1 special character and min 8 char size"

  return (
    <>
      {authState != AuthTypes.AuthState.AUTH_FORGOT_PASSWORD_CONFIRM ? (
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <MainDiv>
                <StyledCard>
                  <StyledAvatar>
                    <StyledIconForgot>
                      <ContactPhone />
                    </StyledIconForgot>
                  </StyledAvatar>
                  <StyledTitle>{translate('domain.auth.forgot.title')}</StyledTitle>
                  <StyledHint>{translate('domain.auth.forgot.description')}</StyledHint>
                  <StyledForm>
                    <StyledInputSecondary>
                      <Field
                        autoFocus
                        name="username"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('domain.auth.forgot.username')}
                        disabled={loading}
                        color="secondary"
                      />
                    </StyledInputSecondary>
                  </StyledForm>
                  <StyledCardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && (
                        <CircularProgress
                          size={20}
                          thickness={2}
                          style={{ marginRight: 10 }}
                        />
                      )} {translate('domain.auth.forgot.button')}
                    </Button>
                  </StyledCardActions>
                  <StyledCardActions>
                    <StyledLink to="/login">Back to Login</StyledLink>
                  </StyledCardActions>
                </StyledCard>

              </MainDiv>
            </form>
          )}
        />
      ) : (
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <MainDiv>
                  <StyledCard>
                    <StyledAvatar>
                      <StyledIconSignup>
                        <ContactPhone />
                      </StyledIconSignup>
                    </StyledAvatar>
                    <StyledTitle>{translate('domain.auth.forgot.title')}</StyledTitle>
                    <StyledHint>{translate('domain.auth.forgot.description')}</StyledHint>
                    <StyledForm>
                      <StyledInput>
                        <Field
                          autoFocus
                          name="username"
                          // @ts-ignore
                          component={renderInput}
                          label={translate('domain.auth.forgot.username')}
                          disabled={loading}
                        />
                      </StyledInput>
                      <StyledInput>
                        <Field
                          name="code"
                          // @ts-ignore
                          component={renderInput}
                          label={translate('domain.auth.forgot.code')}
                          disabled={loading}
                        />
                      </StyledInput>
                      <StyledInput>
                        <Field
                          name="password"
                          // @ts-ignore
                          component={renderInput}
                          type="password"
                          label={translate('domain.auth.forgot.password')}
                          disabled={loading}
                        />
                      </StyledInput>
                      <StyledInput>
                        <Field
                          name="confirmPassword"
                          // @ts-ignore
                          component={renderInput}
                          type="password"
                          label={translate('domain.auth.forgot.confirmPassword')}
                          disabled={loading}
                        />
                      </StyledInput>
                    </StyledForm>
                    <StyledCardActions>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                      >
                        {loading && (
                          <CircularProgress
                            size={20}
                            thickness={2}
                            style={{ marginRight: 10 }}
                          />
                        )} {translate('domain.auth.forgot.confirmButton')}
                      </Button>
                    </StyledCardActions>
                  </StyledCard>
                </MainDiv>
              </form>
            )}
          />
        )}
    </>
  )
}

export default ForgotPasswordPage