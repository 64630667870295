
import React from 'react';
var Handlebars = require('handlebars');

export const truncate =(str: any, len:any, words:boolean)=> {
  var safe = Handlebars.Utils.escapeExpression(str);
  var safe = str;
  var tooLong = safe.length > len;
  var s_ = tooLong ? safe.substr(0, len) : safe;
  if (words && tooLong) {
    var index = s_.lastIndexOf(' ');
    if (index !== -1) {
      s_ = s_.substr(0, index);
    }
  }
  return  new Handlebars.SafeString(tooLong ? s_ + ' ...' : s_);
};


export const LimitedTextField = ({ source, record = {} }: any) => {

  // console.log('with ', source, record);
  const text = truncate(record.data.address, 40, true)
  // console.log('with new text', text.string, text.string.length);
  return (<span>{text.string}</span>)
};