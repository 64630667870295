import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation, withRouter, Link, useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify, useAuthProvider, useDataProvider } from 'ra-core';
// import { darkTheme } from './themes' 
import { push } from 'connected-react-router'
import { StyledCard, StyledTitle, MainDiv, IconAvatar, StyledAvatar, StyledHint, StyledForm, StyledInput, StyledCardActions, StyledLink } from './components/auth'

import * as AuthActions from './store/actions/auth'
import { darkTheme } from './themes';
import { CardHeader, Typography, CardMedia } from '@material-ui/core';
import { NewPasswordComponent } from './components/NewPasswordComponent';
import logo from './assets/logo.png';

const required = (value: any) => (value ? undefined : 'Required')

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

enum AuthMode {
  LOGIN,
  NEWPASSWORD
}

const Login = (props: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const [user, setUser] = useState(null);

  const [mode, setMode] = useState(AuthMode.LOGIN);

  const handleSubmit = async (auth: FormValues) => {
    console.log('in handleSubmit');
    setLoading(true);

    try {
      console.log('before login', authProvider);
      console.log('data Provider', dataProvider);
      console.log('auth: ', auth);
      const user = await login(auth, location.state ? location.state.nextPathname : '/')
      console.log('with result: ', user);

      // if (user.challengeName) {
      //   switch (user.challengeName) {
      //     case "NEW_PASSWORD_REQUIRED":
      //       console.log("NEW_PASSWORD_REQUIRED")
      //       dispatch(AuthActions.willChallengeNewPassword(user));
      //       break;
      //     case "MFA_SETUP":
      //       console.log('REQUIRE MFA SETUP')
      //       dispatch(AuthActions.willChallengeMFASetup(user));
      //     default:
      //       break;
      //   }
      // }

    } catch (error) {

      console.log('with error: ', error)
      switch (error.code) {
        case "UserNotFoundException":
          setLoading(false);
          notify('UserNotFound', 'warning');
          break;

        default:
          break;
      }
      const user: any = error

      if (user.challengeName) {
        switch (user.challengeName) {
          case "NEW_PASSWORD_REQUIRED":
            console.log("NEW_PASSWORD_REQUIRED")
            // dispatch(AuthActions.willChallengeNewPassword(user));
            // yield put(push('/auth/newpassword'))
            setUser(user);
            setMode(AuthMode.NEWPASSWORD);
            setLoading(false);
            // history.push({ pathname: '/auth/newpassword', state: { user: user } })

            break;
          case "MFA_SETUP":
            console.log('REQUIRE MFA SETUP')
            dispatch(AuthActions.willChallengeMFASetup(user));
          case "SOFTWARE_TOKEN_MFA":
            console.log('SOFTWARE_TOKEN_MFA')
            dispatch(AuthActions.willChallengeMFA(user))
          default:
            break;
        }
      }
      if (error.code) {
        console.log('with error code', error.code);
        switch (error.code) {
          case "PasswordResetRequiredException":
            console.log('in Password Required Exception');
            dispatch(AuthActions.willChallengePasswordReset())
            break;
          default:
            notify(error.code, 'warning');
            setLoading(false);
            break;
        }
      }
    }
  };

  const validate = (values: FormValues) => {

    console.log('in validate');

    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <>
      <MainDiv>
        <StyledCard>
          <div style={{textAlign: 'center'}}>
            <img src={logo} width="300" />
          </div>
          {/* <Typography align="center" variant="h3" component="h4">AlwaysOn</Typography> */}
          {mode == AuthMode.LOGIN &&
            <Form
              onSubmit={handleSubmit}
              // validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  {/* <StyledAvatar>
                    <IconAvatar>
                      <LockIcon />
                    </IconAvatar>
                  </StyledAvatar> */}
                  <StyledTitle>Login Utente</StyledTitle>
                  <StyledHint>Hint: inserisci le credeziali</StyledHint>
                  <StyledForm>
                    <StyledInput>
                      <Field validate={required}
                        name="username"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('ra.auth.username')}
                        disabled={loading}
                      />
                    </StyledInput>
                    <StyledInput>
                      <Field
                        name="password"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                      />
                    </StyledInput>
                  </StyledForm>
                  <StyledCardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                        />
                      )}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </StyledCardActions>
                  <StyledCardActions>
                    <Button
                      disabled
                      variant="contained"
                      type="button"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        console.log('click')
                        dispatch(push('/auth/signup'))
                      }}>Signup</Button>
                    {/* <a href="#" onClick={() => {
              dispatch(push('/auth/signup'))
            }}>SIGNUP</a> */}
                  </StyledCardActions>
                  <StyledCardActions>
                    <StyledLink to="/auth/forgot">Password dimenticata?</StyledLink>
                  </StyledCardActions>

                </form>

              )}
            />
          }
          {mode == AuthMode.NEWPASSWORD &&
            <NewPasswordComponent currentUser={user} onResult={(result: any) => {
              console.log('done with change pwd: ', result);
              notify('Password Updated', 'info');
              setMode(AuthMode.LOGIN);
            }} />
          }
        </StyledCard>
        <Notification />
      </MainDiv>
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={darkTheme}>
    <Login {...props} />
  </ThemeProvider>
);

export default withRouter(LoginWithTheme);