export default `

mutation deleteProbe ($id:ID!, $recordId: String!) {
    deleteProbe(id: $id, recordId: $recordId){
        id
        domainId
        recordId
    }
}

`