export default `

mutation updateSimpleRecord(
    $domainId: String,
    $id: ID,
    $ttl: Int,
    $values: [String],
    $name: String,
    $type: RecordType
){
    updateSimpleRecord(
        domainId: $domainId,
        id: $id,
        ttl: $ttl,
        values: $values,
        name: $name,
        type: $type
    ){
        domainId
        id
        ttl
        name
        type
    }
}

`