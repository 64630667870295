export default `

query getDomain(
    $id: String
){
  getDomain(
        id: $id
    ){
      user
      name
      type
      id
      data {
        HostedZone {
          Id
          Name
        }
        DelegationSet {
          NameServers
        }
      }      
    }
}

`