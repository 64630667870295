export default `

mutation addSimpleRecord(
    $id: String,
    $name: String,
    $type: String,
    $values: [String],
    $ttl: Int,
    $domainId: String
){
    addSimpleRecord(
        id: $id,
        domainId: $domainId,
        type:  $type,
        ttl: $ttl, 
        values: $values,
        name: $name
    ){
        id
        domainId
        type
        policy
        ttl
        name
    }
}
`