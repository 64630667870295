import React, { useState } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  ShowButton,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  EditButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton
} from 'react-admin';
import { List, Datagrid, TextField, BooleanField, ReferenceField, ReferenceManyField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { cloneElement, useMemo } from 'react';
import {
  ReferenceInput,
  SimpleForm,
  Button,
  SelectInput,
} from 'react-admin';
import * as DomainStore from '../store/domain'
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { StatusField } from '../components/StatusField';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     popover: {
//       pointerEvents: 'none',
//     },
//     paper: {
//       padding: theme.spacing(1),
//     },
//   }),
// );

const ListActions = (props: any) => {
  const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
  } = props;
  const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
          })}
          <CreateButton basePath={basePath} />
      </TopToolbar>
  );
};

export const ProbeList = (props: any) => {

  console.log('received props: ', props)

  const { record } = props;
  return (
    <List {...props} filter={{ domain: props.options.domain }} actions={<ListActions/>}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="type" />
        <BooleanField source="enabled" />
        <StatusField source="status" />
        <ReferenceField label="Record" source="recordId" reference="records">
          <TextField source="name" />
        </ReferenceField>
        {/* <TextField source="id" /> */}
        {/* <TextField source="domainId" />  */}
        <ReferenceField label="Domain" source="domainId" reference="domains">
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
};

// const redirect = (_basepath: any, _id: any, data: any) => {
//   console.log('pippo', data)
//   return `/records`
// };

export const AddProbePage = (props: any) => {

  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
  console.log('currentDomain: ', currentDomain);
  const [type, setType] = useState("HTTP");

  return (
    <Create redirect="/probes" title="Add new probe" {...props}>
      <SimpleForm defaultValue={{ type: "HTTP", recordId: "", frequency: "1m", sensitivity: "medium", enabled: true }} redirect="list" >

        <div style={{ flexGrow: 1, width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain name" source="domainName" validate={[required("Domain name required")]} defaultValue={currentDomain.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain Id" source="domainId" validate={[required("Domain Id required")]} defaultValue={currentDomain.id} />
            </Grid>
          </Grid>
        </div>

        <ReferenceInput
          source="recordId"
          label="Record Name"
          filter={{ isActive: true, domain: props.options.domain, paginate: false }}
          reference="records"
          fullWidth={true}
        // allowNull
        // format={(v: any) => (!v ? null : v)}
        >
          <SelectInput defaultValue="" optionValue="id" label="Record name" optionText="name" validate={[required("Record name required")]} source="records" />
        </ReferenceInput>
        <TextInput label="Probe Names" source="name" fullWidth={true} validate={[required("Probe names required")]} />
        <div style={{ flexGrow: 1, width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <SelectInput defaultValue="" label="Probe Type" fullWidth={true} source="type" validate={[required("Probe Type required")]} choices={[
                { id: 'HTTP', name: 'HTTP' },
                { id: 'DNS', name: 'DNS' },
                { id: 'FTP', name: 'FTP' },
                { id: 'Imap4', name: 'Imap4' },
                { id: 'NTP', name: 'NTP' },
                { id: 'Ping', name: 'Ping' },
                { id: 'Pop3', name: 'Pop3' },
                { id: 'SMTP', name: 'SMTP' },
                { id: 'whois', name: 'Whois' }
              ]}
                value={type}
                onChange={
                  (e: any) => {
                    setType(e.target.value)
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <BooleanInput label="Enable check" fullWidth={true} source="enabled" />
            </Grid>
          </Grid>
          <Grid item xs={10} sm={6} md={4} xl={2} lg={3} >
            <SelectInput defaultValue="" label="Auto Switch DNS" fullWidth={true} source="autoSwitchDns" validate={[required("Auto switch required")]} choices={[
              { id: 'immediately', name: 'Immediately' },
              { id: '1 minute switch', name: 'Switch in 1 minute', disabled:true },
              { id: '5 minutes switch', name: 'Switch in 5 minutes', disabled:true },
              { id: '15 minutes switch', name: 'Switch in 15 minutes', disabled:true },
              { id: '30 minutes switch', name: 'Switch in 30 minutes', disabled:true },
              { id: 'never', name: 'Never' }
            ]} />
          </Grid>
        </div>
        <SelectInput defaultValue="" label="Check Frequency" fullWidth={true} source="frequency" validate={[required("Check frequency required")]} choices={[
          { id: '1', name: '1 minute' },
          { id: '3', name: '3 minutes' },
          { id: '5', name: '5 minutes' },
          { id: '15', name: '15 minutes' },
          { id: '30', name: '30 minutes' },
          { id: '60', name: '1 hour' },
          { id: '240', name: '4 hours' },
          { id: '360', name: '6 hours' },
          { id: '720', name: '12 hours' },
          { id: '1440', name: '1 day' }
        ]} />
        <SelectInput defaultValue="" label="Sensitivity" fullWidth={true} source="sensitivity" validate={[required("Sensivity required")]} choices={[
          { id: 'veryhigh', name: 'Very High (no recheck)' },
          { id: 'high', name: 'High (2 rechecks)' },
          { id: 'medium', name: 'Medium (5 rechecks)' },
          { id: 'low', name: 'Low (7 rechecks)' },
          { id: 'verylow', name: 'Very Low (10 rechecks)' }
        ]} />
        <NumberInput label="Timeout" defaultValue={5} source="timeout" validate={[required("Timeout required")]} />
        {(type === "HTTP") &&
          <div style={{ flexGrow: 1, width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10}>
                <TextInput label="Url" source="url" fullWidth={true} validate={[required("Url required")]} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <BooleanInput label="Follow redirects" fullWidth={true} source="redirects" />
              </Grid>
            </Grid>
          </div>
        }
        {(type === "DNS") &&
          <>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>

                  <TextInput label="DNS server" fullWidth={true} source="dnsserver" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <NumberInput label="DNS port" fullWidth={true} defaultValue={53} source="dnsport" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <SelectInput defaultValue="" label="Protocol" fullWidth={true} source="dnsprotocol" choices={[
                    { id: 'udp', name: 'udp' },
                    { id: 'tcp', name: 'tcp' }
                  ]} />
                </Grid>
              </Grid>
            </div>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>

                  <SelectInput defaultValue="" label="Query" fullWidth={true} source="dnsquery" choices={[
                    { id: 'a', name: 'A' },
                    { id: 'aaaa', name: 'AAAA' },
                    { id: 'any', name: 'ANY' },
                    { id: 'cname', name: 'CNAME' },
                    { id: 'mx', name: 'MX' },
                    { id: 'ns', name: 'NS' },
                    { id: 'ptr', name: 'PTR' },
                    { id: 'soa', name: 'SOA' },
                    { id: 'srv', name: 'SRV' },
                    { id: 'txt', name: 'TXT' },
                  ]} />
                </Grid>
                <Grid item xs={12} sm={4}>

                  <SelectInput defaultValue="" label="RD bit" fullWidth={true} source="dnsrdbit" choices={[
                    { id: '1', name: '1' },
                    { id: '0', name: '0' }
                  ]} />
                </Grid>
                <Grid item xs={12} sm={4}>

                  <SelectInput defaultValue="" label="DNSSEC" fullWidth={true} source="dnssec" choices={[
                    { id: 'notverify', name: 'Do not verify DNSSEC' },
                    { id: 'verify', name: 'Verify DNSSEC authentication data' }
                  ]} />
                </Grid>
              </Grid>
            </div>
            <TextInput label="Value" source="dnsvalue" fullWidth={true} />
            <TextInput label="Expected Response" source="dnsresponse" fullWidth={true} />
          </>
        }


      </SimpleForm>
    </Create >
  )
}