
export const preCommitCallback = (action: "create" | "overwrite", values: any[], currentDomain: any) => {
  console.log("preCommitCallback action: ", action)
  console.log("preCommitCallback values: ", values)
  return Promise.resolve(
    values.map((element, index) => {

      element["values"] && element["type"] == "MX" && (element["values"] = element["values"].replace(/\\n/g, '\n'))
      element["values"] && element["type"] == "TXT" && (element["values"] = element["values"].replace(/\\n/g, '\n'))
      if (typeof element["name"] == 'string') {
        console.log("name: ", index, element)

        element["domainId"] = currentDomain.id
        element["domainName"] = currentDomain.name
        // element["policy"] = "SIMPLE"
        element["ttl"] = element.ttl != undefined ?  element.ttl : 86400

        element["name"] = element["name"] == "" ? currentDomain.name + '.'
          : element["name"] + '.' + currentDomain.name + '.'
        console.log("name: ", element["name"] , index, element)
        return element
      }
      else {
        console.log("no name: ", index, element)
      }

    }).filter(element => element !== undefined)
  )

  // history.push("/records")
}