import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import Inbox from '@material-ui/icons/Inbox';
import ContentAdd from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, useDataProvider } from 'react-admin';
import { push } from 'connected-react-router'
import { useHistory } from "react-router-dom";
import { ImportButton } from "react-admin-import-csv";
import * as DomainStore from '../store/domain'
import { preCommitCallback } from '../components/bulk'

const useStyles = makeStyles(
  theme => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light'
          ? 'inherit'
          : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
);

export const AddRecordEmptyPage = (props: any) => {

  const translate = useTranslate();
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const history = useHistory();
  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
  const config = {
    // A function to translate the CSV rows on import 
    preCommitCallback: (action: "create" | "overwrite", values: any[]) => preCommitCallback(action, values, currentDomain)
  }

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate("ra.page.empty")}
        </Typography>
        <Typography variant="body1">
          {translate("ra.page.invite")}
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          label={"ra.page.create"}
          onClick={() => history.push('/records/create')}  >
          {<ContentAdd />}
        </Button>
        <ImportButton {...props} {...config} />
      </div>
    </>
  );
};
