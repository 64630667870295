// export default `

// query listDomains(
//     $offset: Int,
//     $max: Int,
//     $sort: String,
//     $order: String
// ){
//   listDomains(
//         offset: $offset,
//         max: $max,
//         sort: $sort,
//         order: $order
//     ){
//       id
//       name
//       createdTs
//       modifiedTs
//       nameservers
//     }
// }

// `

export default `

query listDomains{
  listDomains{
      user
      id
      type
      name
      data {
        HostedZone {
          Id
          Name
        }
        DelegationSet {
          NameServers
        }
      }
    }
}

`