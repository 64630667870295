import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes, useFormState } from 'react-final-form';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledLink, StyledHint, StyledForm, StyledInput, StyledCardActions } from '../components/addrecord'
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
// import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { uuid } from 'uuidv4';
import {
  Create,
  Edit,
  ReferenceInput,
  ReferenceField,
  SimpleForm,
  FormWithRedirect,
  Button,
  TextInput,
  EditButton,
  number,
  required,
  SelectInput,
  TextField,
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  ShowButton,
  useDataProvider,
  SaveButton,
  DeleteButton,
  BooleanInput,
  Toolbar,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { FormTab } from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import * as DomainStore from '../store/domain'
import Grid from '@material-ui/core/Grid';
import { parse } from 'query-string';
import { linkToRecord, Record } from 'ra-core';
import { cloneElement, useMemo } from 'react';
import { LimitedTextField } from '../components/truncate'

const IdField = ({ source, basePath = '', record = {} }: any) => <Link to={`${linkToRecord(basePath, record && record.id)}/show`}><span>{record[source]}</span></Link>;

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};


export const NotificationList = (props: any) => {
  const { record, basePath = '' } = props;
  return (
    <>
      {/* <p>Lista dei domini</p> */}
      <List {...props} filter={{ domain: props.options.domain }} actions={<ListActions />}>
        <Datagrid>
          <ReferenceField label="Record" source="recordId" reference="records">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Type" source="data.type" />
          <LimitedTextField label="Address" source="data.address" />
          {/* <TextField source="id" /> */}
          {/* <IdField source="id"></IdField> */}
          <ReferenceField label="Domain" source="domainId" reference="domains">
            <TextField source="name" />
          </ReferenceField>
          <IdField></IdField>
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  )
}


export const NotificationShow = (props: any) => (
  <Show {...props} label="Notification details">
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Domain" source="domainId" reference="domains">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Record" source="recordId" reference="records">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Type" source="data.type" />
      <TextField label="Address" source="data.address" />
      <TextField label="Enabled" source="data.enabled" />
    </SimpleShowLayout>
  </Show>

)