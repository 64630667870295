export default `

query listProbes(
    $domainId: String
){
  listProbes(
    domainId: $domainId
    ){
      id
      domainId
      enabled
      recordId
      name
      type
      status
      statusDescription
      statusUpdateDate
    }
}

`