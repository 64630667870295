export default `

mutation updateNotification($id: ID!, $recordId: String!, $input: NotificationInput
    ){
        updateNotification(id: $id, recordId: $recordId, input:$input){
            id
            recordId
            domainId
            data {
                type
                address
                enabled
            }
        }
    }

`