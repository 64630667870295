import React, { useState } from 'react';
import { styled } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { useLocation, withRouter, Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';



export const MainDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'flex-start',
  background: 'url(https://cdn.shortpixel.ai/client/q_lossless,ret_img,w_1470,h_827/https://www.sundata.cloud/wp-content/uploads/2020/04/Scalata-dellHimalaya.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

export const IconAvatar = styled(Avatar)((theme: any) => {
  return (
    {
      backgroundColor: theme.theme.palette.secondary.main
    }
  )
}
)

export const StyledIconSignup = styled(Avatar)((theme: any) => ({
  backgroundColor: theme.theme.palette.primary.main
}))

export const StyledIconForgot = styled(Avatar)((theme: any) => ({
  backgroundColor: theme.theme.palette.secondary.main
}))

export const StyledCard = styled(Card)(({theme}: any) => {

  return ({
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
    },
    minWidth: 700,
    marginTop: '6em',
    backgroundColor: theme.palette.type == "dark" ? 'black' : 'white'
  })
})

export const StyledCardActions = styled(CardActions)({
  padding: '0 1em 1em 1em'
})

export const StyledAvatar = styled('div')({
  margin: '1em',
  display: 'flex',
  justifyContent: 'center'
})

export const StyledTitle = styled('div')(({theme}: any) => ({
  marginTop: '1em',
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.secondary.main
}))

export const StyledHint = styled('div')(({theme}: any) => ({
  marginTop: '0.5em',
  fontSize: '0.8em',
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.grey[500]
}))

export const StyledForm = styled('div')({
  padding: '0 1em 1em 1em'
})

export const StyledInput = styled('div')({
  marginTop: '1em'
})

export const StyledInputSecondary = styled('div')(({theme}: any) => ({
  marginTop: '1em',
  color: theme.palette.secondary.main
}))

export const StyledLink = styled(Link)(({theme}: any) => ({
  textAlign: 'center',
  width: '100%',
  textDecoration: 'none',
  color: theme.palette.secondary.main
}))