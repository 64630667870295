import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation, withRouter } from 'react-router-dom';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledHint, StyledForm, StyledInput, StyledCardActions, StyledIconSignup } from '../components/auth'
import LockIcon from '@material-ui/icons/Lock';
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import authProvider from '../authProvider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AuthSelectors from '../store/selectors/auth'
import * as AuthActions from '../store/actions/auth'
import * as AuthTypes from '../store/types/auth'
import QRCode from 'qrcode.react';

const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined);


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

interface FormValues {
  otpCode: string;
}
const required = (value: any) => (value ? undefined : 'Required')

const { Form } = withTypes<FormValues>();

const SetupMFAPage = () => {

  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const user = useSelector(AuthSelectors.getUserData)
  const dispatch = useDispatch()
  const authState: AuthTypes.AuthState = useSelector(AuthSelectors.getAuthState)
  const code = useSelector(AuthSelectors.getOtpCode);
  // const str = "test";
  const str = "otpauth://totp/AWSCognito:" + user.username + "?secret=" + code + "&issuer=businesscontinuitylite";

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    console.log('on handle submit')
    const result = dispatch(AuthActions.willConfirmMFASetup(user, values.otpCode, "SOFTWARE_TOKEN_MFA"));
  };


  return (
    <MainDiv>
      <StyledCard>
        <Form
          onSubmit={handleSubmit}
          // validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <StyledAvatar>
                <StyledIconSignup>
                  <ContactPhone />
                </StyledIconSignup>
              </StyledAvatar>
              <StyledHint>{translate('domain.auth.mfa.title')}</StyledHint>
              <StyledCardActions>
                <QRCode  style={{ paddingTop: 10, marginLeft: 'auto', marginRight: 'auto'}} value={str} />
              </StyledCardActions>
              <StyledForm>
                <StyledInput>
                  <Field validate={required}
                    name="otpCode"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('domain.auth.mfa.code')}
                    disabled={loading}
                  />
                </StyledInput>
              </StyledForm>
              <StyledCardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('domain.auth.mfa.confirmButton')}
                </Button>
              </StyledCardActions>
            </form>

          )}
        />
      </StyledCard>
    </MainDiv>
  )
}

export default SetupMFAPage