export default `
mutation addHttpProbe(
  $recordId: String,
  $name: String,
  $domainId: String,
  $enabled: Boolean,
  $frequency: String,
  $sensitivity: String,
  $autoSwitchDns: String,
  $timeout: Int,
  $address: String,
  $redirect: Boolean,
  $type: String
){
  addHttpProbe(
    base: {
      recordId: $recordId,
      name: $name,
      type: $type,
      domainId: $domainId,
      enabled: $enabled,
      autoSwitchDns: $autoSwitchDns,
      frequency: $frequency,
      sensitivity: $sensitivity,
      timeout: $timeout
    },
    data: {
      url: $address
      followRedirects: $redirect
    }
  ){
    id,
    name,
    domainId,
    data{
      ... on HttpProbe{
        url
        followRedirects
      }
    }
  }
}

`