// in src/authProvider.js

import Amplify, { Auth } from 'aws-amplify';

// in src/authProvider.js



if (process.env.REACT_APP_ENV == "prod") {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_QtIbOpcBe',
      userPoolWebClientId: '62h5u03sknj7s0vc65ecsr4lr7'
    }
  })
} else if (process.env.REACT_APP_ENV == "int") {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_7S7lSWIAP',
      userPoolWebClientId: '38grsc656lc1n3kb1raa6o5vch'
    }
  })
} else {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_9DV8KdBHP',
      userPoolWebClientId: '9t6f1fjk18lpsmmp43gcm85ds'
    }
  })
}

export default {
  // called when the user attempts to log in
  login: async ({ username, password }: any) => {
    // localStorage.setItem('username', username);
    console.log('in authProvider Login')
    const user = await Auth.signIn(username, password);
    console.log('in user: ', user);
    if (user.challengeName) {
      switch (user.challengeName) {
        case "NEW_PASSWORD_REQUIRED":
          return Promise.reject(user);
          break;

        case "MFA_SETUP":
          return Promise.reject(user);
          break;
        case "SOFTWARE_TOKEN_MFA":
          return Promise.reject(user);
        default:
          break;
      }


    } else {
      localStorage.setItem('username', username);
      return Promise.resolve(user);
    }

    // accept all username/password combinations
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('username');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: any) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // return Promise.resolve();
    console.log('in checkAuth')
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),

  challengeNewPassword: async (user: any, newPassword: string) => {

    console.log('in challengeNewPassword');
    const result = await Auth.completeNewPassword(
      user,               // the Cognito User Object
      newPassword,       // the new password
      // OPTIONAL, the required attributes
      {}
    )
    Promise.resolve(result);
  },
  signupUser: async (username: string, email: string, password: string) => {
    console.log('in signupNewUser');
    const user = await Auth.signUp({
      username,
      password,
      attributes: {
        email,          // optional
      }
    });
    console.log('after signup with user: ', user)
  },
  confirmSignupUser: async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  },
  setupTOTP: async (user: any) => {
    try {
      const code = await Auth.setupTOTP(user)
      return code
    } catch (error) {
      console.log('in error setup TOTP token')
    }
  },
  confirmTOTP: async (user: any, code: string, mfaType: any) => {
    const loggedUser = await Auth.verifyTotpToken(
      user,   // Return object from Auth.signIn()
      code   // Confirmation code  
    );
  },
  confirmSignin: async (user: any, code: string, mfaType: any) => {
    const loggedUser = await Auth.confirmSignIn(
      user,   // Return object from Auth.signIn()
      code,   // Confirmation code  
      mfaType
    );
    return loggedUser
  },
  forgotPassword: async (username: string) => {
    return await Auth.forgotPassword(username)
  },
  forgotPasswordConfirm: async (username: string, code: string, password: string) => {
    return await Auth.forgotPasswordSubmit(username, code, password);
  }
};