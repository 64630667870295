import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { DataGrid, ColDef, ValueGetterParams } from '@material-ui/data-grid';
import { useDataProvider } from 'react-admin';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { type } from 'os';
import { enableBodyScroll } from 'body-scroll-lock';
import { linkToRecord, Record } from 'ra-core';
import { Link, List, Datagrid, ReferenceField, TextField, ArrayField, ReferenceManyField, Layout, useListContext } from 'react-admin';
import { StatusField } from '../components/StatusField'
import { RecordValueField } from '../components/RecordValueField'

const IdField = ({ source, basePath = '', record = {} }: any) => <Link to={`${linkToRecord(basePath, record && record.id)}/show`}><span>{record[source]}</span></Link>;

const useStyles = makeStyles({
  root: {
    // width: 275,
    marginTop: 40,
    height: 100,
  },
  recordsCard: {
    marginTop: 40,
    minHeight: 460,
  },
  records: {
    minHeight: 360,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  verticalCenter: {
    marginTop: 16,
    marginBottom: 16,
    color: "#1e88e5"
  },
  themeColor: {
    padding: 6,
    paddingRight: 12,
    paddingLeft: 12,
    borderRadius: 6,
    backgroundColor: '#fc4b6c',
    color: 'white',
    fontWeight: 800,

  },
  numberCard: {
    color: 'white'
  },
  content: {
    backgroundColor: 'white',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  nested: {
    paddingLeft: 4,
  },
});

const ListActions = (props: any) => {
  const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
  } = props;
  const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
  } = useListContext();
  return (
    <div></div>
  );
};

export const Dashboard = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [data, setData] = useState([]);
  var dataTMP: any = []
  const columns: ColDef[] = [
    { field: 'recordName', headerName: 'recordName', width: 200 },
    { field: 'primaryIP', headerName: 'primaryIP', width: 150 },
    { field: 'secondaryIP', headerName: 'secondaryIP', width: 150 },
    { field: 'probeName', headerName: 'probeName', width: 150 },
    { field: 'status', headerName: 'Status', width: 100 }
  ]

  const [domainCount, setDomainCount] = React.useState(0);
  const [recordCount, setRecordCount] = React.useState(0);

  React.useEffect(() => {
    dataProvider.getFailoverRecords('records')
      .then((result: any) => {
        console.log('in result', result);

        result.data.getFailoverRecords.forEach((failoverRecord: any) => {
          result.data.getAllProbes.forEach((probe: any) => {
            if (failoverRecord.id == probe.recordId) {
              dataTMP.push({
                id: probe.id,
                recordName: failoverRecord.name,
                primaryIP: failoverRecord.data.primaryValues,
                secondaryIP: failoverRecord.data.secondaryValues,
                probeName: probe.name,
                status: probe.status
              })
            }
          });
        });

        setDomainCount(result.data.listDomains.length);
        setRecordCount(result.data.getAllRecords.length);

        setData(dataTMP)

      })
      .catch((error: any) => {

      })

    return () => { }
  }, [])

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={2}>
        <Card className={`${classes.root} ${classes.numberCard}`}>
          <CardContent className={classes.content}>
            <Typography className={classes.verticalCenter} variant="h5" component="h2">
              Domini: <strong className={classes.themeColor}>{domainCount}</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.verticalCenter} variant="h5" component="h2">
              Record: <strong className={classes.themeColor}>{recordCount}</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.recordsCard}>
          <CardContent>
            <Typography className={classes.verticalCenter} variant="body1" component="h5">
              Status
            </Typography>
            <List
              // location={{ pathname: "/dashboard", search: "", hash: "", state: undefined }}
              // match={{ path: "/", url: "/", isExact: true, params: {} }}
              // options={{}
              // }
              // permissions={null}
              // perPage={4} hasCreate={false} hasEdit={false} hasList={true} hasShow={false} data={[]} total={0}
              basePath="/dashboard"
              actions={<ListActions />}
              resource="dashboard" >
              <Datagrid>
                <ReferenceField label="Record" source="recordId" reference="records" link="show">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="type" />
                <TextField source="ttl" label="TTL" />
                <TextField source="policy"/>
                
                {/* <TextField source="recordName" /> */}
                <StatusField source="status" />
                {/* <TextField source="status" /> */}
                <ReferenceField label="Domain" source="domainId" reference="domains" link="show">
                  <TextField source="name" />
                </ReferenceField>
                <RecordValueField source="data.values" label="Values"/>
                <ReferenceField label="Probe" source="probeId" reference="probes" link="show">
                  <TextField source="name" />
                </ReferenceField>
                {/* <TextField source="primaryIp" />
                <TextField source="secondaryIp" />
                <TextField source="current" /> */}
              </Datagrid>
            </List>
            {/* <DataGrid className={classes.records} rows={data} columns={columns} pageSize={5} /> */}
          </CardContent>
        </Card>
      </Grid>

      <div style={{ height: 400, width: '100%' }}>

      </div>

    </Grid >
  )
}