export default `

  mutation addSimpleRecordBulk(
    $domainId: String,
    $records: [SimpleRecordInput]
  ){
    addSimpleRecordBulk(
      domainId: $domainId,
      records:  $records
    ){
      id
      name
    }
  }
`