import React, { useState } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  useTranslate
} from 'react-admin';

import jsonServerProvider from 'ra-data-json-server';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createMuiTheme } from '@material-ui/core/styles';
import { Button } from 'react-admin'
import englishMessages from 'ra-language-english';
import { List, Datagrid, ReferenceField, TextField, ArrayField, ReferenceManyField, Layout } from 'react-admin';
import { ShowGuesser, SimpleForm, SelectInput, EditButton, ShowButton, Show, SimpleShowLayout, TabbedShowLayout, Tab, SingleFieldList, ReferenceArrayField } from 'react-admin';
import { linkToRecord, Record } from 'ra-core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useGetList, useQueryWithStore, useQuery, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField as CoreTextField } from '@material-ui/core';
import { Button as CoreButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import { InputLabel } from '@material-ui/core';
import { cloneElement, useMemo } from 'react';
import IconEvent from '@material-ui/icons/Event';
import PlusEvent from '@material-ui/icons/Add';
import { AddDomainWizardPage } from './AddDomainWizard';
import { AddRecordPage, AddRecordButton } from './AddRecord';
import { SwitchDNSField } from '../components/SwitchDNSField'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  current: {
    color: "#fc4b6c"
  }
})

const RecordValueField = ({ source, record = {} }: any) => {
  const classes = useStyles();
  
  return (
    <>
      {record.policy == "SIMPLE" &&
        <span>{record.data && record.data.values[0]}</span>
      }
      {record.policy == "FAILOVER" &&
        <SwitchDNSField record={record} />
      }
    </>
  );
}

export const RecordDetail = (props: any) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField label="Domain" source="domainId" reference="domains">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <RecordValueField source="data.values" />
        <TextField source="ttl" />
      </SimpleShowLayout>
    </Show>
  );
}