import React, { useState } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { useDataProvider } from 'react-admin'

const useStyles = makeStyles({
  current: {
    color: "#fc4b6c"
  }
})

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      color: theme.palette.common.white
    },
    track: {
      // border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    checked: {},
  }),
)(Switch);

export const SwitchDNSField = ({ source, record = {} }: any) => {

  console.log("record switch with source, record: ", source, record)

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [switchDNS, setSwitchDNS] = useState(record.currentValue == record.data.primaryValues ? "primary" : "secondary");

  const handleSwitch = async () => {
    setSwitchDNS(switchDNS == "secondary" ? "primary" : "secondary")
    const result = await dataProvider.switchDNS('records', { recordId: record.id, target: switchDNS == "secondary" ? "primary" : "secondary" });
  }

  return (
    <>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          {record.data && switchDNS == "primary" ?
            <b className={classes.current}>
              {record.data.primaryValues}
            </b>
            : record.data.primaryValues
          }
        </Grid>
        <Grid item>
          <AntSwitch checked={switchDNS == "secondary" ? true : false} onChange={handleSwitch} name="switchDNS" />
        </Grid>
        <Grid item>
          {record.data && switchDNS == "secondary" ?
            <b className={classes.current}>
              {record.data.secondaryValues}
            </b>
            : record.data.secondaryValues
          }
        </Grid>
      </Grid>
    </>
  )
}