// in src/App.js
import React, { useState, cloneElement } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  useTranslate
} from 'react-admin';
import { useGetList, useQueryWithStore, useQuery, useDataProvider } from 'react-admin';
import * as DomainStore from '../store/domain'
import AppBar from '@material-ui/core/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { MuiThemeProvider, Typography, Tooltip, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { Autocomplete } from '@material-ui/lab';
import { TextField as CoreTextField } from '@material-ui/core';
import { Button as CoreButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { toggleSidebar } from 'ra-core';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import HideOnScroll from './hideonScroll'
import { UserMenu } from 'ra-ui-materialui';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
);


export const MyAppBar = (props: any) => {

  const dataProvider = useDataProvider();
  const [choices, setChoices] = React.useState([])
  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
  const [inputValue, setInputValue] = React.useState("")
  const [value, setValue] = React.useState({})

  React.useEffect(() => {
    dataProvider.getList('domains', { pagination: { page: 0, perPage: 10 } })
      .then(({ data }: any) => {
        console.log('data getList: ', data);
        setChoices(data);

        if (data.length > 0) {
          var currentDomainTMP: any = null;
          const rawCurrentDomainId = localStorage.getItem('currentDomainId');
          console.log('in rawCurrentDomain: ', rawCurrentDomainId);

          if (rawCurrentDomainId == null) {
            currentDomainTMP = data[0];
          }
          else {
            const domainIdInData = data.some((element: { id: string | null; }) => element.id == JSON.parse(rawCurrentDomainId));
            if (domainIdInData) {
              currentDomainTMP = data.find((x: { id: any; }) => x.id === JSON.parse(rawCurrentDomainId));
            }
            else {
              currentDomainTMP = data[0];
            }
          }

          console.log('currentDomainTMP: in dispatch')
          dispatch(DomainStore.actions.didSelectDomain(currentDomainTMP));
          setValue(currentDomainTMP)
          setInputValue(currentDomainTMP.name)
        }
      })
      .catch((error: any) => {
        console.log('data getList error: ', error);

      })
  }, []);

  React.useEffect(() => {
    console.log('in currentDomain changes', currentDomain)
    dataProvider.getList('domains', { pagination: { page: 0, perPage: 10 } })
      .then(({ data }: any) => {
        console.log('data getList: ', data);
        setChoices(data);
        setValue(currentDomain);
      })
      .catch((error: any) => {
        console.log('data getList error: ', error);

      })
    return () => { }
  }, [currentDomain])

  const history = useHistory();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs')
  );
  const {
    open,
    classes: classesOverride,
    color = 'secondary',
    className,
    logout,
    userMenu,
    ...rest
  } = props;

  const classes = useStyles(props);
  return (
    <HideOnScroll>
      <AppBar className={className} color={color}  {...props}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <Tooltip
            title={translate(
              open
                ? 'ra.action.close_menu'
                : 'ra.action.open_menu',
              {
                _: 'Open/Close menu',
              }
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          {/* <Typography variant="h6" id="react-admin-title" /> */}
          <Box style={{ width: 180 }}><Typography variant="h6">Always On</Typography></Box>
          <Box ml={2} mr={2}><Typography variant="h6">{' - '}</Typography></Box>
          <Box style={{ width: 500 }}><Typography variant="h6" id="react-admin-title" /></Box>
          {/* <div>Seleziona il dominio</div> */}
          <div style={{ width: '100%', border: '0px solid #00ff00' }}>
            <div style={{ float: "right" }}>
              {choices.length > 0 ? (
                <Autocomplete
                  options={choices}
                  className="header"
                  getOptionLabel={(option: any) => {
                    //  console.log('optionLabel: ', option)
                    return option && option.name ? option.name : option.data ? option.data.name : ""
                  }}
                  style={{ width: 300, color: 'white', float: 'left', marginTop: 8, marginRight: 8, marginBottom: 8 }}
                  renderInput={(params: any) => <CoreTextField color="primary" variant="outlined" {...params} label={translate("domain.select")} />}
                  onChange={(e: any, value: any) => {
                    console.log('in select on change with: ', value)
                    dispatch(DomainStore.actions.didSelectDomain(value));
                    setValue(value);
                    localStorage.setItem('currentDomainId', JSON.stringify(value.id));
                  }}
                  size="small"
                  disableClearable
                  defaultValue={choices[0]}
                  // inputValue={inputValue}
                  // onInputChange={(data: any)=>{
                  //   console.log('in onChange', data)
                  //   setInputValue(inputValue);
                  // }}
                  value={value}
                  getOptionSelected={(a: any, b: any) => {
                    // console.log('in getOptionSelected with ', a, b)
                    if (a.id == b.id) {
                      return true
                    } else {
                      return false
                    }
                  }}
                />

              ) : (
                  <div></div>
                )}
              <Box component="span" m={1} style={{ paddingTop: 4, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <CoreButton onClick={() => {
                  history.push('/domains');
                }} size="small" variant="contained" disableElevation color="primary">{translate('domain.manage')}</CoreButton>
              </Box>

            </div>

          </div>
          {cloneElement(<UserMenu />, { logout })}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
};