import React, { useEffect } from 'react';
import {
  Edit,
  ReferenceField,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  TextField,
  useDataProvider,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';

const NotificationAddressField = ({ source, record = {} }: any) => {

  return (
    <>
      {record.data.type == "webhook" ?
        <>
          <TextInput label="Link webhook" source="link" fullWidth={true} validate={[required("Link webhook required")]} />
          <TextInput label="Body webhook" source="body" fullWidth={true} validate={[required("Body webhook required")]} />
          <SelectInput label="Metodo" source="method" fullWidth={false} validate={[required("Metodo required")]} choices={[
            { id: 'post', name: 'Post' },
            { id: 'get', name: 'Get' },
          ]}
          />
          <ArrayInput label="Headers webhook" source="header" fullWidth={true} >
            <SimpleFormIterator disableAdd={false} disableRemove={false}>
              <TextInput label="Name header webhook" fullWidth={true} source="headerName" validate={[required("Header webhook required")]} />
              <TextInput label="Content header webhook" fullWidth={true} source="headerContent" validate={[required("Header webhook required")]} />
            </SimpleFormIterator>
          </ArrayInput>
        </>
        :
        <TextInput label="Address" source="data.address" fullWidth={true} />
      }
    </>
  )
}

export const EditNotificationPage = (props: any) => {

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getOne(props.resource, { id: props.id })
      .then(({ data }: any) => {
        console.log('data getOne: ', data);
      })
      .catch((error: any) => {
        console.log('data getOne error: ', error);
      })
  }, []);

  return (
    <>
      <Edit {...props} title="Edit Notification">
        <SimpleForm>
          <TextField source="id" />
          <ReferenceField label="Domain" source="domainId" reference="domains">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Record" source="recordId" reference="records">
            <TextField source="name" />
          </ReferenceField>
          <SelectInput disabled label="Type" source="data.type" fullWidth={true} choices={[
            // { id: 'email', name: 'Email' },
            // { id: 'sms', name: 'SMS' },
            { id: 'webhook', name: 'Webhook' },
            // { id: 'pagerduty', name: 'PagerDuty' },
            // { id: 'pushover', name: 'PushOver' },
            { id: 'slack', name: 'Slack' },
            { id: 'telegram', name: 'Telegram' },
            // { id: 'hipchat', name: 'HipChat' }
          ]} ></SelectInput>
          <NotificationAddressField source="data.address" />
          <BooleanInput label="Enabled Notification" fullWidth={true} source="data.enabled" ></BooleanInput>
        </SimpleForm>
      </Edit>
    </>
  )
}