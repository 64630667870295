import React, { useState } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  useTranslate
} from 'react-admin';

import jsonServerProvider from 'ra-data-json-server';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createMuiTheme } from '@material-ui/core/styles';
import { Button } from 'react-admin'
import englishMessages from 'ra-language-english';
import { List, Datagrid, ReferenceField, TextField, ArrayField, ReferenceManyField, Layout } from 'react-admin';
import { ShowGuesser, SimpleForm, SelectInput, EditButton, ShowButton, Show, SimpleShowLayout, TabbedShowLayout, Tab, SingleFieldList, ReferenceArrayField } from 'react-admin';
import { linkToRecord, Record } from 'ra-core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useGetList, useQueryWithStore, useQuery, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField as CoreTextField } from '@material-ui/core';
import { Button as CoreButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import { InputLabel } from '@material-ui/core';
import { cloneElement, useMemo } from 'react';
import IconEvent from '@material-ui/icons/Event';
import PlusEvent from '@material-ui/icons/Add';
import { AddDomainWizardPage } from './AddDomainWizard';
import { AddRecordPage, AddRecordButton } from './AddRecord';

const NameServersField = ({ record = {} }: any) =>
  <div style={{ marginBottom: 10 }}>
    <InputLabel style={{ marginBottom: 10 }}><small><span>Nameservers:</span></small></InputLabel>
    {record.data ? (
      <>
        {record.data.DelegationSet.NameServers.map((value: any) => {
          return <div key={`${value}`}>{value} </div>
        })}
      </>
    ) : (
        <span>no name server</span>
      )}

  </div>;

const postRowClick = (id: any, basePath: any, record: any) => {
  console.log('POSTROWCLICK', id, basePath, record)
  return 'edit'
};

export const DomainShow = (props: any) => {
  const [modal, setModal] = useState(false);
  const toggleAddrecord = () => setModal(!modal);
  console.log('domainShow')
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          {/* <TextField source="name" /> */}
          <TextField source="domain" />
          <TextField source="type" />
          <NameServersField source="record.data.DelegationSet.NameServers" />
        </Tab>
        <Tab label="records">
          <AddRecordButton />
          <TextField source="id" />
          <TextField source="domain" />
          <ReferenceManyField label="Records" reference="records" target="id">
            <Datagrid rowClick={postRowClick}>
              {/* <TextField source="id" /> */}
              <TextField source="name" />
              {/* <ReferenceField  label="Domain Id" source="id" reference="domains">
                <TextField source="domain.id" />
              </ReferenceField> */}
              <TextField source="policy" />
              <TextField source="type" />
              <TextField source="primaryIp" label="Primary IP" />
              <TextField source="secondaryIp" label="Secondary IP" />
              <TextField source="ttl" label="ttl" />
              {/* <TextField source="failover" label="failover" /> */}
              <TextField source="healthcheckIp" label="Health Check IP" />
              <TextField source="healthcheckPort" label="Port" />
              <TextField source="healthcheckType" label="Type" />
              <EditButton />
            </Datagrid>

          </ReferenceManyField>

        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}