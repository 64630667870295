export default `

mutation deleteNotification ($id:ID!, $recordId:String!) {
    deleteNotification(id: $id, recordId: $recordId){
        id
        recordId
        domainId
        data {
            type
            address
            enabled
        }
    }

}

`