
export default `

query getRecords(
    $ids: [String]
){
  getRecords(
    ids: $ids
    ){
      id
      name
      type
      policy
      ttl
      domainId
      data{
        ... on SimpleCacheRecordData{
          values
        }
        ... on FailOverCacheRecordData{
          primaryValues
          secondaryValues
        }
      }
    }
}

`