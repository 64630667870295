import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledLink, StyledHint, StyledForm, StyledInput, StyledCardActions } from '../components/addrecord'
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
// import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { uuid } from 'uuidv4';
import { InputTextField } from '../components/InputTextField';
import { Grid, Box, DialogActions } from '@material-ui/core';

import {
    Create,
    Edit,
    ReferenceInput,
    ReferenceField,
    SimpleForm,
    Button,
    TextInput,
    required,
    SelectInput,
    TextField,
    useEditController,
    FormWithRedirect,
    useDataProvider,
    SaveButton,
    DeleteButton
} from 'react-admin';
import { useHistory } from "react-router-dom";

import { FormTab } from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import * as DomainStore from '../store/domain'

import { parse } from 'query-string';


const styles = {
    button: {
        marginTop: '1em'
    }
};
interface FormValues {
    recordName: string;
    recordtype: string;
    primaryIp: string;
    secondaryIp: string;
}
const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);
const requiredMEssage = (value: any) => (value ? undefined : 'Required')
const string_validation = (s: any) => (typeof s !== 'string') ? "Must be a string!" : undefined;

const RecortTypes = [
    "SOA", "A", "TXT", "NS", "CNAME", "MX", "NAPTR", "PTR", "SRV", "SPF", "AAAA", "CAA"
]

// const RecordTypesItems = RecortTypes.map((r, i) => <MenuItem key={uuid()} value={i}>{r}</MenuItem>)
const RecordTypesItems = RecortTypes.map((r, i) => ({ id: r, name: r }))


const { Form } = withTypes<FormValues>();

const ip_validation = (value: any) => (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value) ? undefined : "Invalid IP")
const number_validation = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)
const geq_number_validation = (value: any) => (isNaN(value) ? 'Must be equal or greater than 0' : ((value < 0) ? "Must be > 0" : undefined))
const number = (message = 'Must be a number') =>
    (value: any) => value && isNaN(Number(value)) ? message : undefined;

const MyEdit = (props: any) => {
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = useEditController(props);
    return (
        <div>
            <h1>{defaultTitle}</h1>
            {React.cloneElement(props.children, {
                basePath,
                record,
                redirect,
                resource,
                save,
                saving,
                version,
            })}
        </div>
    );
}

export const EditRecordPage = ({ ...props }: any) => {
    const translate = useTranslate();
    const { name: name } = parse(props.location.search);
    const [policy, setPolicy] = React.useState("SIMPLE");
    const [type, setType] = React.useState("A");
    const [record, setRecord] = React.useState({});
    const dataProvider = useDataProvider();
    const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    // console.log('in EditRecordPage', props, record);

    // const {
    //     record, // record fetched via dataProvider.getOne() based on the id from the location
    // } = useEditController(props);
    // console.log('with record: ', record);

    React.useEffect(() => {
        dataProvider.getOne(props.resource, { id: props.id })
            .then(({ data }: any) => {
                console.log('data getOne: ', data);
                setPolicy(data.policy);
                setType(data.type);
                setRecord(data);
            })
            .catch((error: any) => {
                console.log('data getOne error: ', error);
            })
    }, []);

    return (
        <Edit title="Edit record" {...props}>
            {/* <SimpleForm> */}
            <div style={{ padding: 20, margin: 10 }}>
                <FormWithRedirect
                    resource="records"
                    redirect="/records"
                    initialValues={record}
                    // initialValues={{ ttl: 120, domainId: currentDomain.id, domainName: currentDomain.name, policy: "SIMPLE", type: "A" }}
                    save={async (data: any) => {
                        console.log("update data: ", data)
                        setSaving(true);
                        const result = await dataProvider.update('records', { data: data });
                        console.log('with update result: ', result)
                        setSaving(false);
                        history.push("/records")
                    }}
                    {...props}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        loading,
                        ...formProps
                    }: any) => {

                        console.log('with formProps: ', formProps);

                        return (
                            <>
                                {/* <ReferenceInput
                    source="id" label="Domain Name"
                    reference="domains"
                >
                    <TextInput defaultValue={name} value={name} />
                </ReferenceInput> */}
                                <div style={{ flexGrow: 1, width: '100%' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField source="id" label="id record" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <InputTextField label="Nome Dominio" record={currentDomain} source="name" />
                                        </Grid>
                                    </Grid>

                                    <TextInput disabled options={{ fullWidth: true }} source="name" />
                                    {/* <ReferenceField label="Domain" source="domainId" reference="domains">
                                    <TextInput options={{ fullWidth: true }} source="name" />
                                </ReferenceField> */}
                                    <TextField label="Record name" source="name" />
                                    {/* <TextInput disabled label="Domain name" source="domain" />
                <TextInput disabled label="Domain id" source="domainId" /> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>

                                            <TextInput disabled label="Routing policy" options={{ fullWidth: true }} source="policy" />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>

                                            <SelectInput disabled source="type" label="Record type" options={{ fullWidth: true }} choices={RecordTypesItems} value={type} onChange={(e: any) => setType(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextInput label={translate('domain.domain.newrecord.ttl')} options={{ fullWidth: true }} source="ttl" validate={geq_number_validation} />
                                        </Grid>

                                    </Grid>
                                    {(policy === "SIMPLE" && type === "A") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.ips')} source="data.values" validate={ip_validation} />}
                                    {(policy === "SIMPLE" && type === "AAAA") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.AAAA')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "CAA") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.CAA')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "CNAME") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.CNAME')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "MX") && <TextInput className="fifth-step" options={{ fullWidth: true }} multiline label={translate('domain.domain.newrecord.simpleValues.MX')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "NAPTR") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.NAPTR')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "NS") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.NS')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "PTR") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.PTR')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "SPF") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.SPF')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "SRV") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.SRV')} source="data.values" validate={string_validation} />}
                                    {(policy === "SIMPLE" && type === "TXT") && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.TXT')} source="data.values" validate={string_validation} />}
                                    {(policy === "FAILOVER") && (type === "A") &&
                                        < div >
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="data.primaryValues" validate={ip_validation} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>

                                                    <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.secondaryIp')} source="data.secondaryValues" validate={ip_validation} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                </div>
                                <DialogActions>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                        label="aggiorna il record"
                                    />
                                    <DeleteButton basePath="/records" record={record} undoable={true} />
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        label="Back"
                                        component="span"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                    </Button>
                                </DialogActions>
                                {/* {(record.policy === "SIMPLE" && <TextInput className="fifth-step" options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.ips')} source="values" validate={ip_validation} />} */}

                                {/* <TextInput label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="primaryIp" validate={ip_validation} />
                <TextInput label={translate('domain.domain.newrecord.simpleValues.secondaryIp')} source="secondaryIp" validate={ip_validation} /> */}
                                {/* <TextInput label={translate('domain.domain.newrecord.simpleValues.hcEndpoint')} source="healthcheckIp" validate={ip_validation} /> */}
                                {/* </SimpleForm> */}

                            </>
                        )
                    }
                    }
                />
            </div>
        </Edit>
    )
};



// export default AddRecordPage;