import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Create,
  ReferenceInput,
  FormWithRedirect,
  TextInput,
  number,
  required,
  SelectInput,
  SaveButton,
  DeleteButton,
  BooleanInput,
  Toolbar,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import * as DomainStore from '../store/domain'
import Grid from '@material-ui/core/Grid';

export const AddNotificationPage = (props: any) => {

  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
  const [notificationType, setNotificationType] = useState("slack");
  const [webhookMethod, setWebhookMethod] = useState("post");
  const [recordId, setRecordId] = useState("");

  const validateTelegramId = [required("UserId required"), number("Invalid User Id. Must be only numbers")];

  return (
    <Create redirect="/probes" title="Add new notification" {...props}>
      <FormWithRedirect defaultValue={{ enabled: true, type: notificationType, domainName: currentDomain.name, domainId: currentDomain.id }} redirect="list"
        save={(data: any) => console.log('in on submit: ', data)}
        {...props}
        render={(formProps: any) => {

          console.log('with formProps: ', formProps);
          return (
            <form style={{ margin: 10 }}>

              <Typography variant="h6" gutterBottom>New Notification</Typography>

              <div style={{ flexGrow: 1, width: '100%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain Name" source="domainName" validate={[required("Domain name required")]} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput options={{ fullWidth: true }} InputProps={{ readOnly: true }} label="Domain Id" source="domainId" validate={[required("Domain Id required")]} />
                  </Grid>
                </Grid>
              </div>

              <ReferenceInput
                validate={[required("Record Id required")]}
                source="recordId"
                label="Record Name"
                filter={{ isActive: true, domain: props.options.domain, paginate: false }}
                reference="records"
                fullWidth={true}
                onChange={
                  (e: any) => {
                    console.log('in select target with ', e.target.value)
                    setRecordId(e.target.value)
                  }}
              >
                <SelectInput value={recordId} optionValue="id" label="Record name" optionText="name" source="records" validate={[required("Record name required")]} />
              </ReferenceInput>
              <SelectInput value={notificationType} label="Notification Type" fullWidth={true} source="type" validate={[required("Notification type required")]} choices={[
                { id: 'slack', name: 'Slack' },
                { id: 'telegram', name: 'Telegram' },
                { id: 'webhook', name: 'Webhook' },
              ]}
                onChange={
                  (e: any) => {
                    setNotificationType(e.target.value)
                  }}
              />
              <Box>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box flex={5} mr="2em" ml="0">
                    {notificationType == "slack" ? (
                      <TextInput label="Channel webhook" source="address" fullWidth={true} validate={[required("Channel webhook required")]} />
                    ) : notificationType == "telegram" ? (
                      <TextInput label="Telegram userId" source="address" fullWidth={true} validate={validateTelegramId} />
                    ) : (
                          <>
                            <TextInput label="Link webhook" source="link" fullWidth={true} validate={[required("Link webhook required")]} />
                            {/* <TextInput label="Header webhook" source="header" fullWidth={true} validate={[required("Header webhook required")]} /> */}
                            <TextInput label="Body webhook" source="body" fullWidth={true} validate={[required("Body webhook required")]} />
                            <SelectInput value={webhookMethod} label="Metodo" fullWidth={false} source="method" validate={[required("Metodo required")]} choices={[
                              { id: 'post', name: 'Post' },
                              { id: 'get', name: 'Get' },
                            ]}
                              onChange={
                                (e: any) => {
                                  setWebhookMethod(e.target.value)
                                }
                              }
                            />
                            <ArrayInput label="Headers webhook" source="header" fullWidth={true} >
                              <SimpleFormIterator disableAdd={false} disableRemove={false}>
                                <TextInput label="Name header webhook" fullWidth={true} source="headerName" validate={[required("Header webhook required")]} />
                                <TextInput label="Content header webhook" fullWidth={true} source="headerContent" validate={[required("Header webhook required")]} />
                              </SimpleFormIterator>
                            </ArrayInput>
                          </>
                        )

                    }
                  </Box>
                  <Box flex={1}>
                    <BooleanInput label="Enable Notification" fullWidth={true} source="enabled" />
                  </Box>

                </Box>
              </Box>

              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box flex={1} mr="0.5em">
                    <SaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                    <DeleteButton record={formProps.record} undoable={true} />
                  </Box>
                </Box>
              </Toolbar>
            </form>
          )
        }}
      />
    </Create>
  )
}