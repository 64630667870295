// export const darkTheme = {
//     palette: {
//         primary: {
//             main: '#90caf9',
//         },
//         type: 'dark', // Switching the dark mode on is a single property value change.
//     },
//     overrides: {
//         MuiFilledInput: {
//             root: {
//                 backgroundColor: 'rgba(0, 0, 0, 0.04)',
//                 '&$disabled': {
//                     backgroundColor: 'rgba(0, 0, 0, 0.04)',
//                 },
//             },
//         },
//     },    
// };

import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from "@material-ui/core/styles/createPalette";
import merge from "lodash/merge";
import { defaultTheme } from "react-admin";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        secondary: {
            light: '#5f5fc4',
            main: '#1e88e5',
            dark: '#001064',
            contrastText: '#fff',
        },
        primary: {
            main: "#fc4b6c"
        },
        type: "light", // Switching the dark mode on is a single property value change. 
    })
);

const rawTheme = { palette }

export const darkTheme = createMuiTheme(merge(rawTheme, {

    typography: {
        fontSize: 16,
        fontFamily: 'Quicksand'
    },
    overrides: {

        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTextField: {
            root: {
                color: 'white'
            }
        },
        MuiListItem: {
            root: {
                "&$active": {
                    color: 'blue !important',
                    backgroundColor: 'red !important'
                }
            },
            active: {
                backgroundColor: 'red !important'
            }
        },
        RaMenuItemLink: {
            
            root: {
                "&:hover": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '8px',
                    color: 'white'
                },
                marginLeft: '4px',
                marginBottom: '4px',
                color: 'white'
            },
            active: {
                borderLeftStyle: "none",
                backgroundColor: palette.primary.main,
                borderRadius: '8px',
                marginLeft: '4px',
                color: 'white'
            }
        },
        MuiMenuItem: {
            root: {
                '&$selected': {
                    backgroundColor: palette.primary.main
                },
                // backgroundColor: 'red'
            },
            selected: {
                backgroundColor: palette.primary.main
            }
        },
        MuiDrawer: {
            root: {
                backgroundColor: palette.secondary.main,
                paddingRight: '10px',
                marginRight: 20
            }
        },
        MuiTypography: {
            body2: {
                color: 'black',
                
            },
            root: {
            }
        },
        MuiListItemIcon: {
            root: {
                color: 'white'
            },
            active: {
                color: 'white !important'
            }
        },
        MuiAutocomplete: {
            root: {
                MuiOutlinedInput: {
                    root: {
                        color: 'white'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                color: 'white'
            }
        }        
    },
}));

export const lightTheme = {

    palette: {
        secondary: {
            light: '#00ff00',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
    overrides: {

        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTextField: {
            root: {
                color: 'white'
            }
        }
    },
};