export default `

query getProbe ($id: String) {
    getProbe (id:$id){
        id
        domainId
        enabled
        recordId
        name
        type
        status
        statusDescription
        statusUpdateDate
        frequency
        autoSwitchDns
        sensitivity
        timeout
        data{
            ... on HttpProbe{
              url
              followRedirects
            }
        }

       
    }
}
  
`

