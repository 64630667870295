

export default `

mutation updateFailoverRecord(
    $id: ID
    $domainName: String
    $domainId: String
    $name: String
    $type: RecordType
    $ttl: Int
    $primaryValues: String
    $secondaryValues: String 
    $healthCheckId: String
    $healthCheckEndPoint: String
){
    updateFailoverRecord(
        id: $id
        domainName:$domainName
        domainId: $domainId
        name: $name
        type: $type
        ttl: $ttl
        primaryValues: $primaryValues
        secondaryValues:  $secondaryValues
        healthCheckId: $healthCheckId
        healthCheckEndPoint: $healthCheckEndPoint
    ){
        id
        domainId
        name
        ttl
        type
        data {
          ... on FailOverCacheRecordData {
            primaryValues
            secondaryValues
          }
        }
    }
}

`