export default `

mutation addNotification(
    $domainId: String,
    $recordId: String,
    $type: String,
    $address: String,
    $enabled: Boolean
){
    addNotification(
        domainId: $domainId,
        recordId: $recordId,
        enabled: $enabled,
        type: $type,
        address:  $address,
    ){
        id
    }
}

`