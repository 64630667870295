export default `
mutation switchDns(
  $recordId: String,
  $target: String
){
  switchDns(
    recordId: $recordId,
    target: $target
  )
}
`