import update from 'immutability-helper';
import * as AuthTypes from '../types/auth'

const initialState: AuthTypes.AuthInitialState = {
  user: null,
  authState: AuthTypes.AuthState.AUTH_NONE,
  otpCode: "",
  error: null
}

export const authReducer = (state = initialState, action: AuthTypes.ActionTypes) => {
  switch (action.type) {
    case AuthTypes.AuthTypes.WILL_CHALLENGE_NEW_PASSWORD:
      console.log('before set state')
      // return update(state, { user: { $set: action.user } })
      return state
    case AuthTypes.AuthTypes.WILL_CONFIRM_CHALLENGE_PASSWORD_RESET:
      console.log("before user null")
      return update(state, { user: { $set: null } })
    case AuthTypes.AuthTypes.DID_SIGNUP_NEW_USER:
      return update(state, { authState: { $set: AuthTypes.AuthState.AUTH_SIGNUP_CONFIRM } })
    case AuthTypes.AuthTypes.WILL_CHALLENGE_MFA_SETUP:
      return update(state, { user: { $set: action.user } })
    case AuthTypes.AuthTypes.DID_CHALLENGE_MFA_SETUP:
      return update(state, { otpCode: { $set: action.code } })
    case AuthTypes.AuthTypes.WILL_CHALLENGE_MFA:
      return update(state, { user: { $set: action.user } })
    case AuthTypes.AuthTypes.DID_CONFIRM_SIGNIN:
      return update(state, { user: { $set: action.user } })
    case AuthTypes.AuthTypes.DID_CONFIRM_SIGNIN_FAIL:
      return update(state, { error: { $set: action.error } })
    case AuthTypes.AuthTypes.DID_REQUEST_PASSWORD_RESET:
      return update(state, { authState: { $set: AuthTypes.AuthState.AUTH_FORGOT_PASSWORD_CONFIRM } })
    case AuthTypes.AuthTypes.WILL_CHALLENGE_PASSWORD_RESET:
      return update(state, { authState: { $set: AuthTypes.AuthState.AUTH_FORGOT_PASSWORD_CONFIRM } })
    default:
      return state;
  }
}