// in src/App.js
import React, { useState } from 'react';
import { Admin, Resource, Layout, ShowGuesser, Sidebar, MenuItemLink } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import Login from './Login';
import Signup from './pages/Signup';
import MFASetupPage from './pages/MFA';
import ConfirmSigninPage from './pages/ConfirmSignin';
import { AddRecordPage, AddRecordButton } from './pages/AddRecord';
import { NotificationList, NotificationShow,  } from './pages/Notification';
import { AddNotificationPage } from './pages/AddNotification';
import { EditNotificationPage } from './pages/EditNotification';
import { AddDomainWizardPage } from './pages/AddDomainWizard';
import ForgotPasswordPage from './pages/Forgot';
import { Route } from 'react-router-dom';
import NewPassword from './NewPassword'
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './store/createAdminStore';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { frenchMessages, englishMessages as domainEnglishMessage, italianMessages } from './translation';
import { EditRecordPage } from './pages/EditRecord';
import { Dashboard } from './pages/Dashboard';
import { ProbeList, AddProbePage } from './pages/ProbeList';
import * as DomainStore from './store/domain'
import { useDispatch, useSelector } from 'react-redux';
import { MyAppBar } from './components/header';
import { darkTheme } from './themes';
import { DomainList } from './pages/DomainList'
import { RecordList } from './pages/RecordList'
import { EditProbe } from './pages/EditProbe';
import { DomainShow } from './pages/DomainDetail'
import { ProbeShow } from './pages/ProbeDetail'
import { RecordDetail } from './pages/RecordDetail'
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import { ListGuesser } from 'ra-ui-materialui';

import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/People';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBed, faBell, faCoffee, faGlobe, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'

const customRoutes = [
  <Route exact path="/auth/newpassword" component={NewPassword} noLayout />,
  <Route exact path="/auth/signup" component={Signup} noLayout />,
  <Route exact path="/auth/forgot" component={ForgotPasswordPage} noLayout />,
  <Route exact path="/auth/mfa/setup" component={MFASetupPage} noLayout />,
  <Route exact path="/auth/mfa/confirm" component={ConfirmSigninPage} noLayout />,
  // <Route exact path="/domains/newrecord" component={AddRecordPage} noLayout />
]

const history = createHashHistory();

const messages: { [index: string]: any } = {
  fr: frenchMessages,
  en: { ...domainEnglishMessage, ...englishMessages },
  it: italianMessages
};

const i18nProvider = polyglotI18nProvider((locale: any) =>
  messages[locale] ? messages[locale] : italianMessages,
  "it" // Default locale
);

const useSidebarStyles = makeStyles({
  // drawerPaper: {
  //     backgroundColor: 'red',
  // },
});

const MySidebar = (props: any) => {
  const classes = useSidebarStyles();
  console.log('sidebar with props: ', props)
  return (
    <Sidebar classes={classes} {...props} />
  );
};

const MyLayout = (props: any) => <Layout {...props} appBar={MyAppBar} menu={CustomMenu}  />;

const App = () => {

  return (
    <Provider
      store={createAdminStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <ReduxApp />
    </Provider>
  )
};
export default App;

export const CustomMenu = (props: any) => {
  return (
    <div style={{marginTop: 20}}>
      <MenuItemLink
         to="/"
         primaryText="Dashboard"
         leftIcon={<FontAwesomeIcon icon={faTachometerAlt} />} // pass the icon here
         exact
         {...props}
       />
      <MenuItemLink
         to="/records"
         primaryText="Records"
         leftIcon={<FontAwesomeIcon icon={faBars} />} // pass the icon here
         exact
         {...props}
       />    
      <MenuItemLink
         to="/probes"
         primaryText="Sonde"
         leftIcon={<FontAwesomeIcon icon={faGlobe} />} // pass the icon here
         exact
         {...props}
       /> 
      <MenuItemLink
         to="/notifications"
         primaryText="Notifiche"
         leftIcon={<FontAwesomeIcon icon={faBell} />} // pass the icon here
         exact
         {...props}
       />                    
       {/* other menu links follow... */}
    </div>
  );
};

const ReduxApp = () => {

  const currentDomain = useSelector(DomainStore.selectors.getCurrentDomain);
  console.log('currentDomain: ', currentDomain)
  const translate = useTranslate();

  return (
    <Admin
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      history={history}
      customRoutes={customRoutes}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={darkTheme}
      layout={MyLayout}
      locale="it"
    >
      <Resource name="dashboard" list={ListGuesser} />
      <Resource name="domains" list={DomainList} show={DomainShow} create={AddDomainWizardPage} />
      <Resource name="records" list={RecordList} edit={EditRecordPage} create={AddRecordPage} show={RecordDetail} options={{ label: translate("record.title"), domain: currentDomain.id }} />
      <Resource name="probes" list={ProbeList} show={ProbeShow} create={AddProbePage} edit={EditProbe} options={{ label: translate("probe.title"), domain: currentDomain.id }} />
      <Resource name="notifications" list={NotificationList} show={NotificationShow} edit={EditNotificationPage} create={AddNotificationPage} options={{ label: translate("notification.title"), domain: currentDomain.id }} />
    </Admin>
  )
}