export const englishMessages = {
    NotAuthorizedException: "Wrong Username or Password",
    domain: {
        notification: {
            http_error: 'Network error. Please retry',
        },
        action: {
            save: 'Save',
            delete: 'Delete',
        },
        create: "Add new domain",
        domain: {

            newrecord: {
                recordName: "Record name",
                simpleValues: {
                    ips: "Insert one or more IP addresses.",
                    PTR: "PTR — Pointer The domain name that you want Route 53 to return.",
                    A: "A — IPv4 address",
                    AAAA: "An IP address in IPv6 format, for example, 2001:0db8:85a3:0:0:8a2e:0370:7334.",
                    CAA: "CAA — Certificate Authority Authorization - Three space-separated values",
                    CNAME: "CNAME — Canonical name",
                    MX: "MX — Mail exchange. A priority and a domain name that specifies a mail server, for example, 10 mailserver.example.com.",
                    NAPTR: "NAPTR — Name Authority Pointer. Six space-separated settings ",
                    NS: "NS — Name server. The domain name of a name server, for example, ns1.example.com.",
                    SPF: "SPF — Sender Policy Framework. An SPF record enclosed in quotation marks, for example, \"v=spf1 ip4:192.168.0.1/16-all\".",
                    SRV: "SRV — Service locator. The format of an SRV record is: [priority] [weight] [port] [server host name]",
                    TXT: "TXT — Text. A text record. Enclose text in quotation marks, for example, \"Sample text entry\".",
                    primaryIp: "Insert the primary IP addresses",
                    secondaryIp: "Insert the secondary IP addresses",
                    recordType: "Select the record type",
                    ttl: "Time to live",
                    hcEndpoint: "Healthcheck endpoint"
                }
            }
        },
        auth: {
            new: "Insert New Password",
            confirm: "Confirm New Password",
            newpassword: {
                button: "Change Password"
            },
            signup: {
                username: "Username",
                email: "Email Address",
                emailconfirm: "Confirm Email Address",
                password: "Password",
                passwordConfirm: "Confirm Password",
                button: "Signup",
                confirmButton: "Confirm Signup",
                code: "Verification code"
            },
            mfa: {
                title: "Two Factor Authenticator",
                confirmButton: "Confirm Two Factor Authenticator",
                code: "Enter OPT code"
            },
            confirmSignin: {
                title: "Enter Two Factor Authenticator code"
            },
            forgot: {
                title: "Forgot Password",
                description: "Insert username or email to request the password reset",
                username: "username/email address",
                button: "request reset",
                code: "code",
                password: "new password",
                confirmPassword: "password confirm",
                confirmButton: "Confirm new Password"
            },
        }
    },
};
export const italianMessages = {
    ra: {
        notification: {
            http_error: 'Network error. Please retry',
            item_doesnt_exist: "L'elemento non esiste",
            create: "Crea",
            created: "Elemento creato",
            updated: "Elemento aggiornato",
            deleted: "Elemento cancellato",
            canceled: "Operazione annullata",
            data_provider_error: "Dataprovider error"
        },
        action: {
            save: 'Save',
            delete: 'Delete',
            export: 'Esporta dati',
            create: 'Crea',
            show: 'Visualizza',
            unselect: 'Deseleziona',
            sort: 'Ordina',
            edit: "Modifica",
            bulk_actions: "Azioni bulk",
            undo: "Annulla",
            add: "Aggiungi",
            remove: "Rimuovi"
        },
        auth: {
            new: "Inserire la nuova password",
            logout: "Logout",
            sign_in: "Login",
            username: "Nome Utente",
            password: "Password",
            user_menu: "Utente"
        },
        navigation: {
            page_rows_per_page: "Righe per pagina",
            page_range_info: '%{offsetBegin}-%{offsetEnd} di %{total}',
            prev: "Precedente",
            next: "Successiva",
            no_results: "Nessun Risultato"
        },
        page: {
            empty: "Nessun elemento presente",
            invite: "crea il primo elemento",
            create: 'Crea',
            list: 'Lista elementi',
            show: 'Visualizza',
            dashboard: 'Dashboard',
            edit: "Modifica"
        }
    },
    domain: {
        title: "Domini",
        create: "Aggiungi dominio",
        manage: "Gestisci domini",
        select: "Dominio corrente",
        record: {
            name: "Nome Record",
            policy: "Policy",
            type: "Tipo Record",
            ttl: "TTL",
            value: "Valore del record"
        },
        domain: {
            newrecord: {
                recordName: "Record name",
                simpleValues: {
                    ips: "Insert one or more IP addresses.",
                    PTR: "PTR — Pointer The domain name that you want Route 53 to return.",
                    A: "A — IPv4 address",
                    AAAA: "An IP address in IPv6 format, for example, 2001:0db8:85a3:0:0:8a2e:0370:7334.",
                    CAA: "CAA — Certificate Authority Authorization - Three space-separated values",
                    CNAME: "CNAME — Canonical name",
                    MX: "MX — Mail exchange. A priority and a domain name that specifies a mail server, for example, 10 mailserver.example.com.",
                    NAPTR: "NAPTR — Name Authority Pointer. Six space-separated settings ",
                    NS: "NS — Name server. The domain name of a name server, for example, ns1.example.com.",
                    SPF: "SPF — Sender Policy Framework. An SPF record enclosed in quotation marks, for example, \"v=spf1 ip4:192.168.0.1/16-all\".",
                    SRV: "SRV — Service locator. The format of an SRV record is: [priority] [weight] [port] [server host name]",
                    TXT: "TXT — Text. A text record. Enclose text in quotation marks, for example, \"Sample text entry\".",
                    primaryIp: "Insert the primary IP addresses",
                    secondaryIp: "Insert the secondary IP addresses",
                    recordType: "Select the record type",
                    ttl: "Time to live",
                    hcEndpoint: "Healthcheck endpoint"
                },
                ttl: "TTL"
            }
        },
        auth: {
            new: "Inserisci nuova password",
            confirm: "Conferma nuova password",
            newpassword: {
                button: "Conferma nuova password"
            },
            forgot: {
                title: "Password dimenticata",
                description: "Per ripristinare la password inserisci il tuo nome utente",
                username: "Nome utente",
                button: "Richiedi Reset"
            }
        }

    },
    record: {
        title: "Record",
        add: {
            title: "Aggiungi nuovo record"
        },
        list: {
            title: "Lista Record"
        },
        label: {
            domainName: "Nome Dominio",
            domainId: "ID Dominio",
            recordName: "Nome del Record",
            policy: "Record Policy",
            type: "Tipo di Record",
            ttl: "TimeToLive (TTL)",
            value: "Valore del Record"
        },
        export: {
            button: "Esporta Dati"
        }
    },
    probe: {
        title: "Sonde"
    },
    notification: {
        title: "Notifiche"
    }
};
export const frenchMessages = {
    ra: {
        notification: {
            http_error: 'Erreur réseau, veuillez réessayer',
        },
        action: {
            save: 'Enregistrer',
            delete: 'Supprimer',
        },
    },
};