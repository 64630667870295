import React, { useState } from 'react';
import {
  Admin, Resource, ListGuesser, resolveBrowserLocale,
  TabbedForm,
  FormTab,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
  Create,
  Edit, DateField,
  TextInput,
  DateInput, NumberInput,
  BooleanInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  useTranslate
} from 'react-admin';

import jsonServerProvider from 'ra-data-json-server';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createMuiTheme } from '@material-ui/core/styles';
import { Button } from 'react-admin'
import englishMessages from 'ra-language-english';
import { List, Datagrid, ReferenceField, TextField, ArrayField, ReferenceManyField, Layout } from 'react-admin';
import { ShowGuesser, SimpleForm, SelectInput, EditButton, ShowButton, Show, SimpleShowLayout, TabbedShowLayout, Tab, SingleFieldList, ReferenceArrayField } from 'react-admin';
import { linkToRecord, Record } from 'ra-core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useGetList, useQueryWithStore, useQuery, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField as CoreTextField } from '@material-ui/core';
import { Button as CoreButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import { InputLabel } from '@material-ui/core';
import { cloneElement, useMemo } from 'react';
import IconEvent from '@material-ui/icons/Event';
import PlusEvent from '@material-ui/icons/Add';
import { AddDomainWizardPage } from './AddDomainWizard';
import { AddDomainEmptyPage } from './AddDomainEmpty';

const IdField = ({ source, basePath = '', record = {} }: any) => <Link to={`${linkToRecord(basePath, record && record.id)}/show`}><span>{record[source]}</span></Link>;

const CountField = (props: any) => {
  const { total } = props;
  return (<span>{total}</span>)
};

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    onCreate,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const translate = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {/* <CreateButton basePath={basePath} /> */}
      {/* Add your custom actions */}
      <Button
        onClick={onCreate}
        // label={translate("domain.create")}
        label={translate("domain.create")}
      >
        <PlusEvent />
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
}

export const DomainList = (props: any) => {

  const { record, basePath = '' } = props;
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <List
        {...props}
        actions={
          <ListActions onCreate={() => {
            console.log('in setModalOpen')
            setModalOpen(true);
          }} />}
        filter={{ domain: props.options.domain }}
        empty={<AddDomainEmptyPage />}
        >
        <Datagrid>
          <TextField style={{ fontWeight: '800' }} source="name" />
          <IdField source="id" />
          <ReferenceManyField label="Records" source="id" reference="records" target="id">
            <CountField />
          </ReferenceManyField>
          <ShowButton />
        </Datagrid>
      </List>
      <AddDomainWizardPage modalOpen={modalOpen} setModalOpen={setModalOpen} basePath="/" />
    </>
  )
};