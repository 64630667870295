import * as AuthTypes from '../types/auth';

//WillChallengeNewPassword
export type WillChallengeNewPassword = (
  user: any
) => AuthTypes.WillChallengeNewPassword;

export const willChallengeNewPassword: WillChallengeNewPassword = (
  user: any
) => ({
  user,
  type: AuthTypes.AuthTypes.WILL_CHALLENGE_NEW_PASSWORD
});

//WillConfirmChallengePasswordReset
export type WillConfirmChallengePasswordReset = () => AuthTypes.WillConfirmChallengePasswordReset;

export const willConfirmChallengePasswordReset: WillConfirmChallengePasswordReset = () => ({
  type: AuthTypes.AuthTypes.WILL_CONFIRM_CHALLENGE_PASSWORD_RESET
});

//WillSignupNewUser
export type WillSignupNewUser = (
  username: string,
  email: string,
  password: string
) => AuthTypes.WillSignupNewUser;

export const willSignupNewUser: WillSignupNewUser = (
  username: string,
  email: string,
  password: string
) => ({
  username,
  email,
  password,
  type: AuthTypes.AuthTypes.WILL_SIGNUP_NEW_USER
});

//DidSignupNewUser
export type DidSignupNewUser = (
  username: string
) => AuthTypes.DidSignupNewUser;

export const didSignupNewUser: DidSignupNewUser = (
  username: string
) => ({
  username,
  type: AuthTypes.AuthTypes.DID_SIGNUP_NEW_USER
});

//WillConfirmSignupNewUser
export type WillConfirmSignupNewUser = (
  username: string,
  code: string
) => AuthTypes.WillConfirmSignupNewUser;

export const willConfirmSignupNewUser: WillConfirmSignupNewUser = (
  username: string,
  code: string
) => ({
  username,
  code,
  type: AuthTypes.AuthTypes.WILL_CONFIRM_SIGNUP_NEW_USER
});

//DidConfirmSignupNewUser
export type DidConfirmSignupNewUser = (
  username: string,
) => AuthTypes.DidConfirmSignupNewUser;

export const didConfirmSignupNewUser: DidConfirmSignupNewUser = (
  username: string,
) => ({
  username,
  type: AuthTypes.AuthTypes.DID_CONFIRM_SIGNUP_NEW_USER
});

//WillChallengeMFASetup
export type WillChallengeMFASetup = (
  user: any,
) => AuthTypes.WillChallengeMFASetup;

export const willChallengeMFASetup: WillChallengeMFASetup = (
  user: any,
) => ({
  user,
  type: AuthTypes.AuthTypes.WILL_CHALLENGE_MFA_SETUP
});

//DidChallengeMFASetup
export type DidChallengeMFASetup = (
  code: string
) => AuthTypes.DidChallengeMFASetup;

export const didChallengeMFASetup: DidChallengeMFASetup = (
  code: string
) => ({
  code,
  type: AuthTypes.AuthTypes.DID_CHALLENGE_MFA_SETUP
});

//WillChallengeMFA
export type WillChallengeMFA = (
  user: any,
) => AuthTypes.WillChallengeMFA;

export const willChallengeMFA: WillChallengeMFA = (
  user: any,
) => ({
  user,
  type: AuthTypes.AuthTypes.WILL_CHALLENGE_MFA
});

//WillConfirmMFASetup
export type WillConfirmMFASetup = (
  user: any,
  code: string,
  mfaType: string
) => AuthTypes.WillConfirmMFASetup;

export const willConfirmMFASetup: WillConfirmMFASetup = (
  user: any,
  code: string,
  mfaType: string
) => ({
  user,
  code,
  mfaType,
  type: AuthTypes.AuthTypes.WILL_CONFIRM_MFA_SETUP
});

//DidConfirmMFASetup
export type DidConfirmMFASetup = (
) => AuthTypes.DidConfirmMFASetup;

export const didConfirmMFASetup: DidConfirmMFASetup = (
) => ({
  type: AuthTypes.AuthTypes.DID_CONFIRM_MFA_SETUP
});

//DidConfirmMFASetupFail
export type DidConfirmMFASetupFail = (
  error: any
) => AuthTypes.DidConfirmMFASetupFail;

export const didConfirmMFASetupFail: DidConfirmMFASetupFail = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_CONFIRM_MFA_SETUP_FAIL
});

//WillConfirmMFASetup
export type WillConfirmSignin = (
  user: any,
  code: string,
  mfaType: string
) => AuthTypes.WillConfirmSignin;

export const willConfirmSignin: WillConfirmSignin = (
  user: any,
  code: string,
  mfaType: string
) => ({
  user,
  code,
  mfaType,
  type: AuthTypes.AuthTypes.WILL_CONFIRM_SIGNIN
});

//DidConfirmSignin
export type DidConfirmSignin = (
  user: any
) => AuthTypes.DidConfirmSignin;

export const didConfirmSignin: DidConfirmSignin = (
  user: any
) => ({
  user,
  type: AuthTypes.AuthTypes.DID_CONFIRM_SIGNIN
});

//DidConfirmMFASetupFail
export type DidConfirmSigninFail = (
  error: any
) => AuthTypes.DidConfirmSigninFail;

export const didConfirmSigninFail: DidConfirmSigninFail = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_CONFIRM_SIGNIN_FAIL
});

//WillRequestPasswordReset
export type WillRequestPasswordReset = (
  email: any,
) => AuthTypes.WillRequestPasswordReset;


export const willRequestPasswordReset: WillRequestPasswordReset = (
  username: any,
) => ({
  username,
  type: AuthTypes.AuthTypes.WILL_REQUEST_PASSWORD_RESET
});

//DidRequestPasswordReset
export type DidRequestPasswordReset = (
  user: any
) => AuthTypes.DidRequestPasswordReset;

export const didRequestPasswordReset: DidRequestPasswordReset = (
  data: any
) => ({
  data,
  type: AuthTypes.AuthTypes.DID_REQUEST_PASSWORD_RESET
});

//DidConfirmMFASetupFail
export type DidRequestPasswordResetFail = (
  error: any
) => AuthTypes.DidRequestPasswordResetFail;

export const didRequestPasswordResetFail: DidRequestPasswordResetFail = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_REQUEST_PASSWORD_RESET_FAIL
});

//WillConfirmPasswordReset
export type WillConfirmPasswordReset = (
  username: any,
  code: any,
  password: any,
) => AuthTypes.WillConfirmPasswordReset;


export const willConfirmPasswordReset: WillConfirmPasswordReset = (
  username: any,
  code: any,
  password: any,
) => ({
  username,
  code,
  password,
  type: AuthTypes.AuthTypes.WILL_CONFIRM_PASSWORD_RESET
});

//DidConfirmPasswordReset
export type DidConfirmPasswordReset = (
  data: any
) => AuthTypes.DidConfirmPasswordReset;

export const didConfirmPasswordReset: DidConfirmPasswordReset = (
  data: any
) => ({
  data,
  type: AuthTypes.AuthTypes.DID_CONFIRM_PASSWORD_RESET
});

//DidConfirmPasswordResetFail
export type DidConfirmPasswordResetFail = (
  error: any
) => AuthTypes.DidConfirmPasswordResetFail;

export const didConfirmPasswordResetFail: DidConfirmPasswordResetFail = (
  error: any
) => ({
  error,
  type: AuthTypes.AuthTypes.DID_CONFIRM_PASSWORD_RESET_FAIL
});

//WillChallengePasswordReset
export type WillChallengePasswordReset = (
) => AuthTypes.WillChallengePasswordReset;


export const willChallengePasswordReset: WillChallengePasswordReset = (
) => ({
  type: AuthTypes.AuthTypes.WILL_CHALLENGE_PASSWORD_RESET
});