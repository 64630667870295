import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation, withRouter } from 'react-router-dom';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledLink, StyledHint, StyledForm, StyledInput, StyledCardActions, StyledIconSignup } from '../components/auth'
import LockIcon from '@material-ui/icons/Lock';
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import authProvider from '../authProvider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AuthSelectors from '../store/selectors/auth'
import * as AuthActions from '../store/actions/auth'
import * as AuthTypes from '../store/types/auth'
import { Link } from 'react-router-dom'

const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined);


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

interface FormValues {
  username: string;
  email: string;
  password: string;
}

interface ConfirmFormValues {
  username: string;
  code: string;
}

const { Form } = withTypes<FormValues>();
const ConfirmForm = withTypes<ConfirmFormValues>().Form;

const SignupPage = () => {

  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const user = useSelector(AuthSelectors.getUserData)
  const dispatch = useDispatch()
  const authState: AuthTypes.AuthState = useSelector(AuthSelectors.getAuthState)

  const handleSubmit = async (values: any) => {
    setLoading(true);
    console.log('on handle submit')
    if (authState == AuthTypes.AuthState.AUTH_SIGNUP_CONFIRM) {
      dispatch(AuthActions.willConfirmSignupNewUser(values.username, values.code))
    } else {
      dispatch(AuthActions.willSignupNewUser(values.username, values.email, values.password))
    }
  };

  //Reset Loading status when AUTH_SIGNUP_CONFIRM
  useEffect(() => {
    if (authState == AuthTypes.AuthState.AUTH_SIGNUP_CONFIRM) {
      setLoading(false);
    }
    return () => { }
  }, [authState])

  const required = (value: any) => (value ? undefined : 'Required')
  const email_validation = (value: any) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? undefined : "Invalid Email")
  const password_validation = (value: any) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i.test(value) ? undefined : "Password Must be at least  1 lowercase, 1 uppercase, 1 numeric, 1 special character and min 8 char size"

  return (
    <>
      {authState != AuthTypes.AuthState.AUTH_SIGNUP_CONFIRM ? (
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <MainDiv>
                <StyledCard>
                  <StyledAvatar>
                    <StyledIconSignup>
                      <ContactPhone />
                    </StyledIconSignup>
                  </StyledAvatar>
                  <StyledHint>Register new User</StyledHint>
                  <StyledForm>
                    <StyledInput>
                      <Field
                        autoFocus
                        name="username"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('domain.auth.signup.username')}
                        disabled={loading}
                      />
                    </StyledInput>
                    <StyledInput>
                      <Field
                        validate={composeValidators(required, email_validation)}
                        name="email"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('domain.auth.signup.email')}
                        disabled={loading}
                      />
                    </StyledInput>
                    <StyledInput>
                      <Field
                        validate={composeValidators(required, password_validation)}
                        name="password"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('domain.auth.signup.password')}
                        type="password"
                        disabled={loading}
                      />
                    </StyledInput>
                    <StyledInput>
                      <Field
                        name="passwordconfirm"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('domain.auth.signup.passwordConfirm')}
                        type="password"
                        disabled={loading}
                      />
                    </StyledInput>
                  </StyledForm>
                  <StyledCardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && (
                        <CircularProgress
                          size={20}
                          thickness={2}
                          style={{ marginRight: 10 }}
                        />
                      )} {translate('domain.auth.signup.button')}
                    </Button>
                  </StyledCardActions>
                  <StyledCardActions>
                    <StyledLink to="/login">Back to Login</StyledLink>
                  </StyledCardActions>
                </StyledCard>

              </MainDiv>
            </form>
          )}
        />
      ) : (
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <MainDiv>
                  <StyledCard>
                    <StyledAvatar>
                      <StyledIconSignup>
                        <ContactPhone />
                      </StyledIconSignup>
                    </StyledAvatar>
                    <StyledHint>User Registered</StyledHint>
                    <StyledHint>Insert VerificationCode received in email</StyledHint>
                    <StyledForm>
                      <StyledInput>
                        <Field
                          autoFocus
                          name="code"
                          // @ts-ignore
                          component={renderInput}
                          label={translate('domain.auth.signup.code')}
                          disabled={loading}
                        />
                      </StyledInput>
                      <div><a href="/login">Back to login</a></div>

                    </StyledForm>

                    <StyledCardActions>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                      >
                        {loading && (
                          <CircularProgress
                            size={20}
                            thickness={2}
                            style={{ marginRight: 10 }}
                          />
                        )} {translate('domain.auth.signup.confirmbutton')}
                      </Button>

                    </StyledCardActions>
                  </StyledCard>

                </MainDiv>
              </form>

            )}
          />
        )}

    </>
  )
}

export default SignupPage