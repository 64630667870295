// import update from 'immutability-helper';
import * as UITypes from '../types/ui'

const initialState: UITypes.UiInitialState = {
  loadingStatus: false,
}

export const uiReducer = (state = initialState, action: UITypes.UiActionTypes) => {
  switch (action.type) {
    default:
      return state;
  }
}