import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { StyledCard, MainDiv, IconAvatar, StyledAvatar, StyledLink, StyledHint, StyledForm, StyledInput, StyledCardActions } from '../components/addrecord'
import ContactPhone from '@material-ui/icons/ContactPhone'
import { useTranslate, useLogin, useNotify } from 'ra-core';
// import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { uuid } from 'uuidv4';
import { useHistory } from "react-router-dom";

import {
  Create,
  Edit,
  ReferenceInput,
  ReferenceField,
  SimpleForm,
  FormWithRedirect,
  Button,
  TextInput,
  required,
  SelectInput,
  TextField,
  SaveButton,
  useDataProvider

} from 'react-admin';
import { FormTab } from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import * as DomainStore from '../store/domain'
import { parse } from 'query-string';
import { Dialog, Stepper, Step, StepLabel, makeStyles, Card, Paper, DialogContent, DialogActions, Typography, Box, Grid } from '@material-ui/core';

const steps = [
  'Inserisci il nome del dominio da registrare',
  'Configura i nameserver sul tuo registar',
  'Aggiungi i primi record',
  'Configura il failover',
  'Configura le notifiche'
];

const styles = {
  button: {
    marginTop: '1em'
  }
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});



export const AddDomainWizardPage = ({ modalOpen, setModalOpen, basePath, ...rest }: any) => {
  const translate = useTranslate();
  const redirect = (_basePath: any, _id: any, data: any) => {
    console.log('pippo', data)
    return `/records`
  };

  // const { domain_id: domain_id } = parse(props.location.search);
  const [policy, setPolicy] = useState("SIMPLE");
  const [rtype, setRType] = useState("A");
  // const [modalOpen, setModalOpen] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [domainData, setDomainData] = React.useState({} as any);
  const dataProvider = useDataProvider();

  const ip_validation = (value: any) => (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value) ? undefined : "Invalid IP")
  const number_validation = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)
  const geq_number_validation = (value: any) => (isNaN(value) ? 'Must be equal or greater than 0' : ((value < 0) ? "Must be > 0" : undefined))


  // console.log('in AddRecordPage', props, domain_id);
  const classes = useStyles();
  let history = useHistory();

  const cardUseStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: '2opx',
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  }));
  const cardClasses = useStyles();
  const [saving, setSaving] = React.useState(false);
  const dispatch = useDispatch();

  const RecordPolicies = ["SIMPLE", "FAILOVER", "AWS_CUSTOM", "NODEPING"];

  const RecordTypes = [
    // "SOA",
    "A",
    // "TXT",
    // "NS",
    "CNAME",
    // "MX",
    // "NAPTR",
    // "PTR",
    // "SRV",
    // "SPF",
    // "AAAA",
    // "CAA"
  ];

  const RecordTypesItems = RecordTypes.map((r, i) => ({ id: r, name: r }))
  const RecordPoliciesItems = RecordPolicies.map((r, i) => ({ id: r, name: r }))
  
  return (
    <>
      <Dialog
        open={modalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onBackdropClick={() => setModalOpen(false)}
      >
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Create redirect={redirect} basePath={basePath} title="Add new record" {...rest} resource="domains">

          <Paper style={{ margin: 10 }} elevation={3} >
            <div style={{ padding: 20, margin: 10 }}>
              {activeStep == 0 &&

                <FormWithRedirect
                  resource="posts"
                  initialValues={{ type: "aws/route53" }}
                  save={async (data: any) => {
                    console.log('in onSave with ', data)
                    setSaving(true);
                    const result = await dataProvider.create('domains', { data: data });
                    console.log('with result: ', result);
                    const domainData = await dataProvider.getOne('domains', { id: result.data.id })
                    console.log('with domain data: ', domainData);
                    setDomainData(domainData);
                    dispatch(DomainStore.actions.didSelectDomain(domainData));
                    setSaving(false);
                    setActiveStep(1);
                  }}
                  render={({
                    handleSubmitWithRedirect,
                    pristine,
                    loading
                  }: any) => (
                      <>
                        <TextInput label="nome di dominio" source="name" fullWidth={true} />
                        <DialogActions>
                          <SaveButton
                            handleSubmitWithRedirect={
                              handleSubmitWithRedirect
                            }
                            pristine={pristine}
                            saving={saving}
                            disabled={loading}
                            label="crea il dominio"
                          />
                        </DialogActions>
                      </>
                    )}
                />
              }
              {activeStep == 1 &&
                <>
                  <div><Typography align="center" variant="body1" component="span">Inserisci i seguenti ns record sul tuo provider di dominio:</Typography></div>
                  {domainData.data.data && domainData.data.data.DelegationSet.NameServers.map((value: any) => (
                    <div key={value}>
                      <Typography variant="body1" component="span">
                        <Box fontWeight="fontWeightBold" m={1}>{value}</Box></Typography>
                    </div>
                  ))}
                  <DialogActions>
                    <Button
                      saving={saving}
                      label="Aggiungi i primi Record"
                      onClick={() => { history.push("records/create") }}
                    />
                  </DialogActions>

                </>

              }
              {activeStep == 2 &&
                <>

                  <FormWithRedirect
                    resource="posts"
                    initialValues={{ ttl: 120, domainId: domainData.data.id, domainName: domainData.data.name }}
                    save={async (data: any) => {
                      console.log('in onSave with ', data)
                      setSaving(true);
                      const result = await dataProvider.create('records', { data: data });
                      console.log('with result: ', result);
                      setSaving(false);
                      setActiveStep(2);

                    }}
                    render={({
                      handleSubmitWithRedirect,
                      pristine,
                      loading
                    }: any) => (
                        <>
                          <div style={{ flexGrow: 1, width: '100%' }}>

                            <TextInput options={{ fullWidth: true }} label={translate("domain.record.name")} source="name" />

                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <SelectInput options={{ fullWidth: true }} label={translate("domain.record.policy")} source="policy" defaultValue={policy}
                                  value={policy}
                                  onChange={
                                    (e: any) => {
                                      setPolicy(e.target.value)
                                    }}
                                  choices={RecordPoliciesItems} />
                              </Grid>

                              <Grid item xs={12} sm={4}>

                                <SelectInput options={{ fullWidth: true }} label={translate("domain.record.type")} source="Record type" defaultValue={rtype}
                                  value={rtype}
                                  onChange={
                                    (e: any) => {
                                      setRType(e.target.value)
                                    }}
                                  choices={RecordTypesItems} />
                              </Grid>
                              <Grid item xs={12} sm={4}>

                                <TextInput options={{ fullWidth: true }} label={translate('domain.record.ttl')} source="ttl" validate={geq_number_validation} />
                              </Grid>

                            </Grid>
                            {(policy === "SIMPLE") && <TextInput options={{ fullWidth: true }} label={translate('domain.record.value')} source="values" validate={ip_validation} />}
                            {(policy === "FAILOVER") && (rtype === "A") &&
                              < div >
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="primaryValue" validate={ip_validation} />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>

                                    <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.secondaryIp')} source="secondaryValue" validate={ip_validation} />
                                  </Grid>
                                </Grid>
                              </div>
                            }
                            {(policy === "FAILOVER") && (rtype === "CNAME") &&
                              < div >
                                <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.primaryIp')} source="primaryValues" validate={ip_validation} />
                                <TextInput options={{ fullWidth: true }} label={translate('domain.domain.newrecord.simpleValues.hcEndpoint')} source="healthCheckEndPoint" validate={ip_validation} />
                              </div>
                            }
                          </div>


                          <DialogActions>
                            <SaveButton
                              handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                              }
                              pristine={pristine}
                              saving={saving}
                              disabled={loading}
                              label="aggiungi il record"
                            />
                          </DialogActions>
                        </>
                      )}
                  />

                </>
              }
            </div>
          </Paper>

        </Create >
      </Dialog>
    </>
  )
};
