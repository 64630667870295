import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import Inbox from '@material-ui/icons/Inbox';
import ContentAdd from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

import { AddDomainWizardPage } from './AddDomainWizard';

const useStyles = makeStyles(
  theme => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light'
          ? 'inherit'
          : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
);

export const AddDomainEmptyPage = (props: any) => {

  const translate = useTranslate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate("ra.page.empty")}
        </Typography>
        <Typography variant="body1">
          {translate("ra.page.invite")}
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          label={"ra.page.create"}
          onClick={() => setModalOpen(true)}  >
          {<ContentAdd />}
        </Button>
      </div>
      <AddDomainWizardPage modalOpen={modalOpen} setModalOpen={setModalOpen} basePath="/" />
    </>
  );
};
