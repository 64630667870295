import Amplify, { API, graphqlOperation } from 'aws-amplify';
import listDomains from './graphql/listDomains';
import listProbes from './graphql/listProbes';
import listNotifications from './graphql/listNotifications';
import getDomain from './graphql/getDomain';
import listRecords from './graphql/listRecords';
import getRecords from './graphql/getRecords';
import addRecord from './graphql/addRecord';
import addHttpProbe from './graphql/addHttpProbe';
import addNotification from './graphql/addNotification';
import addFailoverRecord from './graphql/addFailoverRecord';
import addSimpleRecord from './graphql/addSimpleRecord';
import addSimpleRecordBulk from './graphql/addSimpleRecordBulk';
import addDomain from './graphql/addDomain';
import updateRecord from './graphql/updateRecord';
import getNotification from './graphql/getNotification';
import getProbe from './graphql/getProbe';
import { uuid } from 'uuidv4';
import updateSimpleRecord from './graphql/updateSimpleRecord';
import updateFailoverRecord from './graphql/updateFailoverRecord';
import updateNotification from './graphql/updateNotification';
import deleteNotification from './graphql/deleteNotification';
import getFailoverRecords from './graphql/getFailoverRecords';
import deleteProbe from './graphql/deleteProbe';
import updateProbe from './graphql/updateProbe';
import updateHttpProbe from './graphql/updateHttpProbe';
import deleteRecord from './graphql/deleteRecord';
import switchDNS from './graphql/switchDNS';
import { slice, filter, orderBy } from 'lodash';

var myAppConfig = {
  'aws_appsync_graphqlEndpoint': 'https://dgn3cgl7a5e2tpd7t4szi6hxqq.appsync-api.eu-west-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-west-1',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
}

// const _ = require('lodash');
if (process.env.REACT_APP_LOCAL_BACKEND != undefined) {

  myAppConfig = {
    'aws_appsync_graphqlEndpoint': 'http://127.0.0.1:20002/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  }
}
if (process.env.REACT_APP_ENV == "int") {
  myAppConfig = {
    'aws_appsync_graphqlEndpoint': 'https://zgblbfydp5et3ph6kpc4txhmku.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  }
}

if (process.env.REACT_APP_ENV == "prod") {
  myAppConfig = {
    'aws_appsync_graphqlEndpoint': 'https://3sa6hfgu7bcn5fddgdwmxcevx4.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  }
}

// const myAppConfig = {
//   'aws_appsync_graphqlEndpoint': 'https://wx2qglpkfvd4le64c26idswere.appsync-api.eu-west-1.amazonaws.com/graphql',
//   'aws_appsync_region': 'eu-west-1',
//   'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
// }



Amplify.configure(myAppConfig);

export default {
  getList: async (resource: string, params: any) => {

    console.log('in get list with resource:params: ', resource, ":", params);
    var domain = ""

    console.log('params: ', params)


    switch (resource) {
      case "dashboard":
        // console.log(graphqlOperation(getFailoverRecords));
        let results: any = await API.graphql(graphqlOperation(getFailoverRecords));
        console.log('with getFailoverRecords: ', results)

        var parsedResult: any = [];

        results.data.getFailoverRecords.forEach((failoverRecord: any) => {
          results.data.getAllProbes.forEach((probe: any) => {
            if (failoverRecord.id == probe.recordId) {
              // console.log('found failover: ', failoverRecord)
              parsedResult.push({
                id: probe.id,
                type: failoverRecord.type,
                ttl: failoverRecord.ttl,
                recordName: failoverRecord.name,
                recordId: failoverRecord.id,
                domainId: failoverRecord.domainId,
                primaryIp: failoverRecord.data.primaryValues,
                secondaryIp: failoverRecord.data.secondaryValues,
                data: {
                  primaryValues: failoverRecord.data.primaryValues,
                  secondaryValues: failoverRecord.data.secondaryValues
                },
                probeName: probe.name,
                probeId: probe.id,
                status: probe.status,
                policy: "FAILOVER",
                statusDescription: probe.statusDescription,
                currentValue: failoverRecord.currentValue
              })
            }
          });
        });
        console.log('with parsedResult: ', parsedResult)
        return {
          data: parsedResult,
          total: parsedResult.length
        }
        break;

      case "probes":
        domain = params.filter.domain;
        console.log(graphqlOperation(listProbes, { domainId: domain }));
        if (domain) {

          let probes: any = await API.graphql(graphqlOperation(listProbes, { domainId: domain }));
          console.log('with probes: ', probes)
          return {
            data: probes.data.listProbes,
            total: probes.data.listProbes.length
          }
        } else {
          return {
            data: [],
            total: 0
          }
        }
        break;
      case "records":
        console.log('in getList records with ', params.filter.domain)
        domain = params.filter.domain;
        const paginate = params.filter.paginate != undefined ? params.filter.paginate : true
        var perPage = params.pagination.perPage
        var page = params.pagination.page
        if (!paginate) {
          perPage = 1000;
          page = 1;
        }
        console.log('page: ', params.filter.paginate, paginate, perPage, page);
        if (domain) {
          console.log(graphqlOperation(listRecords, { domainId: domain }));
          let records: any = await API.graphql(graphqlOperation(listRecords, { domainId: domain, perPage: perPage, page: page }));
          console.log('with records: ', records)


          //Filtering
          var filteredRecords = records.data.listRecords.items;

          if (params.filter.q != undefined) {
            filteredRecords = filter(filteredRecords, function (obj) {
              return obj.name.indexOf(params.filter.q) !== -1;
            });
          }
          //Order
          filteredRecords = orderBy(filteredRecords, params.sort.field, params.sort.order.toLowerCase())

          //Slice
          const sliceStart = (perPage * page) - perPage
          const sliceEnd = sliceStart + perPage
          console.log('sliceStart: ', sliceStart, ' sliceEnd: ', sliceEnd);

          const slicedData = slice(filteredRecords, sliceStart, sliceEnd)
          return {
            data: slicedData,
            total: filteredRecords.length
          }
        } else {
          return {
            data: [],
            total: 0
          }
        }
        break;
      case "notifications":
        console.log('in getList notification with ', params.filter.domain)
        domain = params.filter.domain;
        if (domain) {
          console.log(graphqlOperation(listNotifications, { domainId: domain }));
          let notifications: any = await API.graphql(graphqlOperation(listNotifications, { domainId: domain }));
          console.log('with notifiications: ', notifications)
          return {
            data: notifications.data.listNotifications,
            total: notifications.data.listNotifications.length
          }
        } else {
          return {
            data: [],
            total: 0
          }
        }
        break;
      case "domains":
        console.log('in get list with resource:params: ', resource, ":", params, listDomains);
        const offset = (params.pagination.page - 1) * params.pagination.perPage
        let domains: any = await API.graphql(graphqlOperation(listDomains));
        console.log("with results: ", domains);

        return {
          data: domains.data.listDomains,
          total: domains.data.listDomains.length
        }
        break;
    }

  },
  getOne: async (resource: string, params: any) => {
    console.log('in getone: ', resource, 'params', params);
    switch (resource) {
      case "domains":
        const results: any = await API.graphql(graphqlOperation(getDomain, { id: params.id }));
        console.log('getDomain: ', results); // sub: "7d8ca528-4931-4254-9273-ea5ee853f271"
        if (results.data.getDomain !== null) {
          return {
            data: results.data.getDomain
          }
        }
        else {
          return {
            data: {
              id: "345",
              type: "123",
              domain: "www.example.com",
              user: "foo bar"
            }
          }
        }
      case "records":

        console.log('inside records')
        const recordResult: any = await API.graphql(graphqlOperation(getRecords, {
          ids: [params.id]
        }))
        console.log('with records results: ', recordResult)
        return { data: recordResult.data.getRecords[0] }

      case "probes":
        const probeResult: any = await API.graphql(graphqlOperation(getProbe, { id: params.id }))
        console.log("getProbe result: ", probeResult)
        return {
          data: probeResult.data.getProbe
        }

      case "notifications":
        const result: any = await API.graphql(graphqlOperation(getNotification, { id: params.id }))
        console.log("getNotification:", result)
        const returnData = {
          data: result.data.getNotification
        }
        console.log(returnData);
        return returnData

    }
  },
  getMany: async (resource: string, params: any) => {

    console.log('in getMany: ', resource, params)
    switch (resource) {
      case "dashboard":
        console.log("in getMany dashboard: ", params);


      case "records":
        {
          console.log('in records: ', resource)
          // const domain = params.filter.domain;
          // if (domain) {
          const results: any = await API.graphql(graphqlOperation(getRecords, {
            ...
            params
          }))
          console.log('with records results: ', results)
          return { total: results.data.getRecords.length, data: results.data.getRecords }
          // } else {
          //   return {
          //     data: [],
          //     total: 0
          //   }
          // }
        }
      case "domains":
        {
          const results: any = await API.graphql(graphqlOperation(listDomains))
          console.log("with domains results: ", results);
          return {
            data: results.data.listDomains,
            total: results.data.listDomains.length
          }
        }
      case "notifications":
        {
          console.log('in getMany notification with ', params.filter.domain)
          const domain = params.filter.domain;
          if (domain) {
            console.log(graphqlOperation(listNotifications, { domain: domain }));
            let notifications: any = await API.graphql(graphqlOperation(listNotifications, { domain: domain }));
            console.log('with notifiications: ', notifications)
            return {
              data: notifications.data.listNotifications,
              total: notifications.data.listNotifications.length
            }
          } else {
            return {
              data: [],
              total: 0
            }
          }
        }
        break;

      default:
        break;
    }
    return {
      data: [{ id: 127294, records: 12 }, { id: 127308, records: 12 }, { id: 274246, records: 12 }]
    }
  },
  getManyReference: async (resource: string, params: any) => {
    switch (resource) {
      case "records":
        console.log('in getManyReference: ', resource, params, graphqlOperation(listRecords, { domainId: params.id }));
        const results: any = await API.graphql(graphqlOperation(listRecords, { domainId: params.id }));
        console.log('results', results)
        results.data.listRecords.domainId = params.id;
        return {
          data: results.data.listRecords.items,
          total: results.data.listRecords.length
        }
      case "domains":
        console.log('in GetManyReference, domains')
        break;
      default: console.log('in GetManyReference default case')
    }
  },
  createMany: async (resource: string, params: any) => {
    console.log('Dataprovider CREATE MANY:', resource, params);
    switch (resource) {
      case "records":
        console.log("create many records: ", params)

        const remainsRecords = params.data;
        var returnedRecords: any = []
        try {

          while (remainsRecords.length > 0) {
            console.log('remains items: ', remainsRecords.length)
            const sendingRecords = remainsRecords.splice(0, 25);
            console.log('sending items: ', sendingRecords.length)

            const results: any = await API.graphql(graphqlOperation(addSimpleRecordBulk, {

              domainId: sendingRecords[0].domainId,
              records: sendingRecords

            }))

            console.log('addSimpleRecordBulk result:', results.data);

            if (results.data.addSimpleRecordBulk) {
              returnedRecords.concat(results.data.addSimpleRecordBulk)
              // return { data: { ...params.data, id: uuid() } }
            } else {
              //throw new Error("Error: addRecordBulk failed")
            }

          }
        } catch (error) {
          console.log("Create many API error", error)
          throw new Error(JSON.stringify(error))
        }

        return { data: returnedRecords }
        break;
    }
  },
  create: async (resource: string, params: any) => {
    console.log('Dataprovider CREATE:', resource, params);
    switch (resource) {
      case "records":
        switch (params.data.policy) {
          case 'SIMPLE': {
            console.log('addSimpleRecord', params, graphqlOperation(addSimpleRecord, {
              name: params.data.name != undefined ? params.data.name + '.' + params.data.domainName + '.' : params.data.domainName + '.',
              id: params.data.id,
              type: params.data.type,
              ttl: parseInt(params.data.ttl),
              values: [params.data.values],
              domainId: params.data.domainId
            }));
            try {
              const results: any = await API.graphql(graphqlOperation(addSimpleRecord, {
                name: params.data.name != undefined ? params.data.name + '.' + params.data.domainName + '.' : params.data.domainName + '.',
                id: params.data.id,
                type: params.data.type,
                ttl: parseInt(params.data.ttl),
                values: [params.data.values],
                domainId: params.data.domainId

              }))
              console.log('AddSimpleRecord result:', results.data);

              if (results.data.addSimpleRecord)
                return { data: { ...params.data, id: uuid() } }
              else
                throw new Error("Error: addRecord PIPELINE failed")
            }
            catch (e) {
              console.log("Create API error", e)
              throw new Error(JSON.stringify(e))
            }
          }

          case 'FAILOVER': {
            console.log('addFailoverRecord');

            try {
              const results: any = await API.graphql(graphqlOperation(addFailoverRecord, {
                name: params.data.name + '.' + params.data.domainName + '.',
                id: params.data.id,
                domainId: params.data.domainId,
                type: params.data.type,
                policy: "FAILOVER",
                primaryValues: params.data.primaryValue,
                secondaryValues: params.data.secondaryValue,
                ttl: parseInt(params.data.ttl),
                // healthCheckCallerRef: uuid(),
                // healthCheckEndPoint: params.data.healthCheckEndPoint,
              }))
              console.log('AddFailoverRecord result:', results.data);
              if (results.data.addFailoverRecord)
                return { data: { ...params.data, id: uuid() } }
              else
                throw new Error("Error: addRecord PIPELINE failed")
            }
            catch (e) {
              console.log("Create API error", e)
              throw new Error(JSON.stringify(e))
            }
          }
          default: return { data: { id: uuid() } }
        }
        break;
      case "domains":
        console.log('in create domains with: ', params)

        try {
          const results: any = await API.graphql(graphqlOperation(addDomain, {
            name: params.data.name,
            type: params.data.type,
            // id: params.data.id
          }))
          console.log('addDomain result:', results.data);

          if (results.data.addDomain)
            return { data: { ...params.data, id: results.data.addDomain.id } }
          else
            throw new Error("Error: addDomain PIPELINE failed")
        }
        catch (e) {
          console.log("Create API error", e)
          throw new Error(JSON.stringify(e))
        }
        break;
      case "probes":

        console.log('with probes params: ', params);
        try {
          const results: any = await API.graphql(graphqlOperation(addHttpProbe, {
            domainId: params.data.domainId,
            recordId: params.data.recordId,
            name: params.data.name,
            type: params.data.type,
            enabled: params.data.enabled,
            frequency: params.data.frequency,
            autoSwitchDns: params.data.autoSwitchDns,
            sensitivity: params.data.sensitivity,
            timeout: params.data.timeout,
            address: params.data.url,
            redirect: params.data.redirects

          }))
          console.log('addProbe result:', results.data);

          if (results.data.addHttpProbe)
            return { data: { ...params.data, id: results.data.addHttpProbe } }
          else
            throw new Error("Error: with probes params")
        }
        catch (e) {
          console.log("Create API error", e)
          throw new Error(JSON.stringify(e))
        }
        break;
      case "notifications":
        console.log('with notification params: ', params);
        if (params.data.type == "webhook") {
          params.data.address = JSON.stringify({
            link: params.data.link,
            header: params.data.header,
            body: params.data.body,
            method: params.data.method
          })
        }
        try {
          const results: any = await API.graphql(graphqlOperation(addNotification, {
            domainId: params.data.domainId,
            type: params.data.type,
            recordId: params.data.recordId,
            address: params.data.address,
            enabled: params.data.enabled

          }))
          console.log('addNotification result:', results);

          if (results.data.addNotification)
            return { data: { ...params.data, id: results.data.addNotification.notification } }
          else
            throw new Error("Error: addNotification PIPELINE failed")
        }
        catch (e) {
          console.log("Create API error", e)
          throw new Error(JSON.stringify(e))
        }
        break;
    }
  },
  update: async (resource: string, params: any) => {
    console.log('Dataprovider UPDATE:', resource, params);
    // return { data: { id: uuid() } }

    switch (resource) {
      case 'probes': {
        console.log("updateHTTPProbe params: ", params)
        try {
          const result: any = await API.graphql(graphqlOperation(updateProbe, {
            id: params.data.id,
            recordId: params.data.recordId,
            base: {
              id: params.data.id,
              recordId: params.data.recordId,
              domainId: params.data.domainId,
              name: params.data.name,
              type: params.data.type,
              enabled: params.data.enabled,
              autoSwitchDns: params.data.autoSwitchDns,
              frequency: params.data.frequency,
              sensitivity: params.data.sensitivity,
              timeout: params.data.timeout,
            },
            data: {
              url: params.data.data.url,
              followRedirects: params.data.data.followRedirects
            }
          }))
          console.log("updateHTTPProbe result : ", result)
          return { data: result.data.updateProbe }
        }
        catch (e) {
          console.log("updateHTTPProbe API error: ", e)
          throw new Error(JSON.stringify(e))
        }
      }
      case 'notifications': {
        console.log("updateNotificationData params: ", params)
        var result: any
        if (params.data.data.type == "webhook") {
          const newAddress = JSON.stringify({
            link: params.data.link,
            header: params.data.header,
            body: params.data.body,
            method: params.data.method
          })
          result = await API.graphql(graphqlOperation(updateNotification, {
            id: params.data.id,
            recordId: params.data.recordId,
            input: {
              data: {
                type: params.data.data.type,
                address: newAddress,
                enabled: params.data.data.enabled
              }
            }
          }))
        }
        else {
          result = await API.graphql(graphqlOperation(updateNotification, {
            id: params.data.id,
            recordId: params.data.recordId,
            input: {
              data: {
                type: params.data.data.type,
                address: params.data.data.address,
                enabled: params.data.data.enabled
              }
            }
          }))
        }

        console.log(result);
        console.log("Updating notification result : ", result)
        return { data: result.data.updateNotification }
      }
      case 'records': {
        switch (params.data.policy) {
          case 'SIMPLE': {
            console.log('updateSimpleRecord');
            const results: any = await API.graphql(graphqlOperation(updateSimpleRecord, {
              domainId: params.data.domainId,
              id: params.data.id,
              name: params.data.name,
              values: params.data.data.values,
              ttl: parseInt(params.data.ttl),
              type: params.data.type
            }))
            console.log('updateSimpleRecord results:', results)
            if (results.data.updateSimpleRecord)
              return { data: { ...params.data, id: uuid() } }
            else
              throw new Error("Error: updateSimpleRecord PIPELINE failed")
          }
          case 'FAILOVER': {
            console.log('updateFailoverRecord');
            const results: any = await API.graphql(graphqlOperation(updateFailoverRecord, {
              id: params.data.id,
              domainId: params.data.domainId,
              healthCheckEndPoint: params.data.healthcheckIp,
              healthCheckId: params.data.healthcheckId,
              name: params.data.name,
              primaryValues: params.data.data.primaryValues,
              secondaryValues: params.data.data.secondaryValues,
              ttl: parseInt(params.data.ttl),
              type: params.data.type
            }));
            console.log('updateFailoverRecord results:', results)
            if (results.data.updateFailoverRecord)
              return { data: { ...params.data, id: uuid() } }
            else
              throw new Error("Error: updateFailoverRecord PIPELINE failed")
          }
        }

      }
      default: return { data: { id: uuid() } }
    }

  },
  delete: async (resource: string, params: any) => {
    console.log('Dataprovider DELETE:', resource, params);
    switch (resource) {
      case 'notifications':
        console.log("deleteNotification params: ", params)
        try {
          const result: any = await API.graphql(graphqlOperation(deleteNotification, {
            id: params.id,
            recordId: params.previousData.recordId
          }))
          console.log("Deleting notification with id: ", result)
          return { data: [result.deleteNotification] }
        }
        catch (e) {
          console.log("errore delete", e)
          return { data: {} }
        }
      case 'probes':
        console.log("deleteProbe params: ", params)
        const result: any = await API.graphql(graphqlOperation(deleteProbe, {
          id: params.id,
          recordId: params.previousData.recordId
        }))
        console.log("Deleting probe with id: ", result)
        return { data: [result.deleteProbe] }
      case 'records':
        console.log("delete Record params: ", params)
        const results: any = await API.graphql(graphqlOperation(deleteRecord, {
          id: params.id,
          domainId: params.previousData.domainId
        }))
        console.log("Deleting record with id: ", results)
        return { data: [results.deleteRecord] }


    }
  },
  getFailoverRecords: async (resource: string, params: any) => {

    console.log('in getFailoverRecord');

    switch (resource) {
      case 'records':

        // console.log(graphqlOperation(getFailoverRecords));
        let results: any = await API.graphql(graphqlOperation(getFailoverRecords));
        console.log('with getFailoverRecords: ', results)
        return { data: results.data }
        break;
    }
  },
  switchDNS: async (resource: string, params: any) => {

    console.log('Dataprovider switchDNS:', resource, params);

    switch (resource) {
      case 'records': {
        console.log("switchDNS params: ", params)
        try {
          const result: any = await API.graphql(graphqlOperation(switchDNS, {
            recordId: params.recordId,
            target: params.target,
          }))
          console.log("switchDNS result : ", result)
          return { data: result.switchDns }
        }
        catch (e) {
          console.log("switchDNS API error: ", e)
          throw new Error(JSON.stringify(e))
        }
      }
    }
  }
}
